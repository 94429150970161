.gdpr-cookie {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-blue-light);
	box-sizing: border-box;
	color: var(--color-blue);
	padding: 3.75vw;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 999999;
}

@media (--mq-from-small) {
	.gdpr-cookie {
		display: flex;
		padding: 1.5rem;
		justify-content: space-between;
		align-items: center;
	}
}

@media (--mq-from-wide) {
	.gdpr-cookie {
		padding: 1.5rem calc((100vw - 1380px) / 2);
	}
}

.gdpr-cookie.is-hidden,
.gdpr-cookie[hidden] {
	display: none;
}

@media (--mq-from-small) {
	.gdpr-cookie__description {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-wide) {
	.gdpr-cookie__description {
		flex-basis: 62.5%;
		max-width: 810px;
	}
}

.gdpr-cookie__description p {
	color: var(--color-blue);
	font-size: 1rem;
	font-weight: 400;
}

@media (--mq-up-to-small) {
	.gdpr-cookie__description p {
		font-size: 0.875rem;
	}
}

@media (--mq-up-to-xsmall) {
	.gdpr-cookie__description p {
		font-size: 0.75rem;
	}
}

.gdpr-cookie__description p > a {
	color: var(--color-blue-dark);
	font-weight: 600;
	transition: color 96ms linear;
}

.gdpr-cookie__description p > a:hover {
	color: var(--color-red);
}

.gdpr-cookie__button {
	margin-top: 1rem;
}

@media (--mq-up-to-small) {
	.gdpr-cookie__button {
		font-size: 0.9375rem;
	}
}

@media (--mq-from-small) {
	.gdpr-cookie__button {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
		margin-top: 0;
	}
}
