.wrapper {
	margin-right: auto;
	margin-left: auto;
	position: relative;
	width: 87.5%;
	max-width: 1400px;
}

@media (--mq-from-small) {
	.wrapper {
		width: 92.5%;
	}
}

.wrapper.wrapper--extra-large {
	max-width: 1680px;
}

.wrapper.wrapper--medium {
	max-width: 1156px;
}

@media (--mq-from-small) {
	.wrapper.wrapper--medium {
		width: 82.5%;
	}
}

.wrapper.wrapper--small {
	max-width: 924px;
}

@media (--mq-from-small) {
	.wrapper.wrapper--small {
		width: 75%;
	}
}

@media (--mq-from-medium) {
	.wrapper.wrapper--small {
		width: 66.6666%;
	}
}

.wrapper.wrapper--extra-small {
	max-width: 700px;
}

@media (--mq-from-small) {
	.wrapper.wrapper--extra-small {
		width: 66.6666%;
	}
}

@media (--mq-from-medium) {
	.wrapper.wrapper--extra-small {
		width: 50%;
	}
}

.wrapper.wrapper--standalone {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

@media (--mq-from-small) {
	.wrapper.wrapper--standalone {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
}

@media (--mq-from-medium) {
	.wrapper.wrapper--standalone {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
}

@media (--mq-from-wide) {
	.wrapper.wrapper--standalone {
		margin-top: 4.625rem;
		margin-bottom: 4.625rem;
	}
}

@media (--mq-from-large) {
	.wrapper.wrapper--standalone {
		margin-top: 6.125rem;
		margin-bottom: 6.125rem;
	}
}

.wrapper.wrapper--standalone + .wrapper.wrapper--standalone {
	margin-top: 0;
}

.wrapper.wrapper--centered {
	text-align: center;
}
