.colored-section {
	background-color: transparent;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

@media (--mq-from-small) {
	.colored-section {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (--mq-from-medium) {
	.colored-section {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (--mq-from-wide) {
	.colored-section {
		padding-top: 4.625rem;
		padding-bottom: 4.625rem;
	}
}

@media (--mq-from-large) {
	.colored-section {
		padding-top: 6.125rem;
		padding-bottom: 6.125rem;
	}
}

.colored-section.colored-section--blue-light {
	background-color: var(--color-blue-light);
}

.colored-section:not(.colored-section--blue-light) + .colored-section:not(.colored-section--blue-light),
.colored-section.colored-section--blue-light + .colored-section--blue-light,
.faq + .colored-section,
.page-header + .colored-section:not(.colored-section--blue-light) {
	padding-top: 0;
}

.colored-section__title {
	color: var(--color-blue);
	margin-bottom: 2rem;
	text-align: center;
}

@media (--mq-from-small) {
	.colored-section__title {
		margin-bottom: 3rem;
	}
}

@media (--mq-from-medium) {
	.colored-section__title {
		margin-bottom: 4rem;
	}
}

@media (--mq-from-wide) {
	.colored-section__title {
		margin-bottom: 4.625rem;
	}
}

@media (--mq-from-large) {
	.colored-section__title {
		margin-bottom: 6.125rem;
	}
}

.faq .colored-section__title {
	margin-bottom: 1rem;
}

@media (--mq-from-small) {
	.faq .colored-section__title {
		margin-bottom: 2rem;
	}
}

@media (--mq-from-wide) {
	.faq .colored-section__title {
		margin-bottom: 3rem;
	}
}

@media (--mq-from-large) {
	.faq .colored-section__title {
		margin-bottom: 3.5rem;
	}
}

.colored-section__actions {
	display: flex;
	flex-wrap: wrap;
	margin-top: 2rem;
	margin-bottom: 0;
	justify-content: center;
	text-align: center;
	width: 100%;
}

@media (--mq-from-small) {
	.colored-section__actions {
		margin-top: 3rem;
	}
}

@media (--mq-from-medium) {
	.colored-section__actions {
		margin-top: 4rem;
	}
}

@media (--mq-from-wide) {
	.colored-section__actions {
		margin-top: 4.625rem;
	}
}

@media (--mq-from-large) {
	.colored-section__actions {
		margin-top: 6.125rem;
	}
}

@media (--mq-up-to-small) {
	.colored-section__actions__item:not(:only-child):not(:first-child) {
		display: block;
		margin-top: 1rem;
		margin-bottom: 0;
	}
}

@media (--mq-from-small) {
	.colored-section__actions__item:not(:only-child) {
		margin: 0 1rem;
	}
}

@media (--mq-up-to-xsmall) {
	.colored-section__actions__item.button:only-child {
		width: 100%;
	}
}

.colored-section__actions__item p {
	font-family: var(--font-sans);
}

.colored-section__actions__item p:last-child > a:last-child {
	color: var(--color-blue);
	padding-right: 1rem;
	position: relative;
	text-decoration: none;
	transition-duration: 32ms;
	white-space: nowrap;
}

@media (--mq-up-to-xsmall) {
	.colored-section__actions__item p:last-child > a:last-child {
		display: inline-block;
		white-space: normal;
	}
}

.colored-section__actions__item p:last-child > a:last-child::after {
	background-image: url("../images/arrow-diagonal.svg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	width: 10px;
	height: 10px;
}

.colored-section__actions__item p:last-child > a:last-child:hover {
	color: var(--color-red);
}

.colored-section__actions__item p:last-child > a:last-child:hover::after {
	background-image: url("../images/arrow-diagonal-red.svg");
}
