.card {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--color-black);
	position: relative;
}

.card__link {
	color: var(--color-black);
	display: block;
	text-decoration: none;
	width: 100%;
	height: 100%;
}

.card__link:hover {
	color: var(--color-red);
}

.card__image {
	background-color: var(--color-blue);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	margin-bottom: 1rem;
	padding-bottom: 66.6666%;
	position: relative;
}

@media (--mq-from-wide) {
	.card__image {
		margin-bottom: 1.5rem;
	}
}

@media (--mq-from-wide) {
	.grid--featured .card__image {
		margin-bottom: 2rem;
	}
}

.card__image::before {
	background-color: var(--color-blue);
	content: "";
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 96ms linear;
	width: 100%;
	height: 100%;
}

.card__link:hover .card__image::before {
	opacity: 0.2;
}

.card__meta {
	display: flex;
	margin-bottom: 0.25rem;
}

@media (--mq-from-wide) {
	.card__meta {
		margin-bottom: 0.375rem;
	}
}

.card__meta p {
	color: currentColor;
	font-family: var(--font-sans);
	font-size: 0.75rem;
	line-height: 1rem;
	margin: 0;
}

@media (--mq-from-wide) {
	.card__meta p {
		font-size: 0.875rem;
	}
}

.card__meta .card__meta__separator {
	display: inline-block;
	font-size: 1rem;
	line-height: 0.4375rem;
	padding: 0 0.375rem;
}

.card__title {
	color: currentColor;
	font-weight: 400;
	margin: 0;
}

@media (--mq-from-xsmall) {
	.card__title {
		display: inline-block;
		padding-right: 1rem;
	}
}

@media (--mq-from-medium) {
	.card__title {
		display: inline-block;
		padding-right: 2rem;
	}
}

@media (--mq-from-medium) {
	.grid--featured .card__title {
		padding-right: 15%;
	}
}

.card__content {
	font-family: var(--font-sans);
}

.card__title + .card__content {
	margin-top: 0.25rem;
}

@media (--mq-from-wide) {
	.card__title + .card__content {
		margin-top: 0.75rem;
	}
}

.card__content a {
	color: currentColor;
	text-decoration: none;
}

.card__content a:hover {
	color: var(--color-red);
}

@media (--mq-up-to-small) {
	.card__content,
	.card__meta,
	.card__title {
		padding-right: 6.25vw;
		padding-left: 6.25vw;
	}
}
