.accordion-navigation {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--color-blue);
	display: block;
	overflow: hidden;
	position: relative;
}

.accordion-navigation__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (--mq-from-medium) {
	.accordion-navigation__list {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
	}
}

.accordion-navigation__item {
	flex-basis: 25%;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0;
}

@media (--mq-up-to-medium) {
	.accordion-navigation__submenu .accordion-navigation__item {
		padding-left: 1.5rem;
	}
}

@media (--mq-up-to-medium) {
	.accordion-navigation__item--always-open {
		margin-top: 1.5rem;
	}

	.accordion-navigation__item--always-open .accordion-navigation__link {
		display: none;
	}

	.accordion-navigation__item--always-open .accordion-navigation__submenu {
		display: block;
	}
}

.accordion-navigation__link {
	color: currentColor;
	font-family: var(--font-sans);
	font-weight: 400;
	line-height: 1.625;
	text-decoration: none;
	transition: color 96ms linear;
}

@media (--mq-from-medium) {
	.accordion-navigation__submenu .accordion-navigation__link {
		font-family: var(--font-serif);
	}
}

@media (--mq-from-medium) {
	.accordion-navigation__link:hover {
		color: var(--color-red);
	}
}

.accordion-navigation__link.accordion-navigation__link--active {
	color: var(--color-red);
}

@media (--mq-up-to-medium) {
	.accordion-navigation__link {
		box-sizing: border-box;
		display: block;
		padding: 0 2rem 0 0;
		position: relative;
		width: 100%;
	}
}

@media (--mq-up-to-medium) {
	.accordion-navigation__submenu .accordion-navigation__link {
		font-size: 0.875rem;
		line-height: 2rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.accordion-navigation__link__toggle {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	outline: 0;
	padding: 0;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	width: 2rem;
	height: 2rem;
	z-index: 2;
}

.accordion-navigation__link__toggle:focus,
.accordion-navigation__link__toggle:hover {
	box-shadow: none;
	outline: 0;
}

@media (--mq-from-medium) {
	.accordion-navigation__link__toggle {
		display: none;
	}
}

mr-accordion-navigation:not(.is-resolved) .accordion-navigation__link__toggle {
	display: none;
}

.accordion-navigation__link__toggle svg:not(.icon) {
	display: none;
}

.accordion-navigation__link__toggle .icon {
	fill: var(--color-blue);
	pointer-events: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: fill 96ms linear;
	width: 20px;
	height: 20px;
}

.accordion-navigation__link--active .accordion-navigation__link__toggle .icon {
	fill: var(--color-red);
}

.accordion-navigation__link__toggle[aria-expanded="true"] .icon {
	fill: var(--color-red);
	transform: translate(-50%, -50%) rotate(-180deg);
}

@media (--mq-up-to-medium) {
	.accordion-navigation__link__label {
		display: inline-block;
		font-size: 1.125rem;
		line-height: 2.25rem;
		position: relative;
		height: 2.25rem;
		z-index: 1;
	}
}

@media (--mq-up-to-medium) {
	.accordion-navigation__link__toggle[aria-expanded="true"] + .accordion-navigation__link__label {
		color: var(--color-red);
	}
}

@media (--mq-from-medium) {
	.accordion-navigation__link__label {
		display: inline-block;
		font-weight: 600;
		margin-bottom: 1rem;
	}
}

@media (--mq-from-small) {
	.has-open-navigation .mobile-navigation .accordion-navigation__link__label {
		font-size: 1.375rem;
	}
}

.accordion-navigation__submenu {
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (--mq-from-medium) {
	.accordion-navigation__submenu {
		box-sizing: border-box;
		padding-right: 1rem;
	}
}

@media (--mq-up-to-medium) {
	.accordion-navigation__submenu:not([hidden]) {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
}

mr-accordion-navigation:not(.is-resolved) .accordion-navigation__submenu {
	display: block;
}

.accordion-navigation__message {
	font-size: 0.875rem;
	margin-bottom: 1rem;
}

@media (--mq-from-medium) {
	.accordion-navigation__message {
		font-size: 1rem;
		margin-bottom: 2rem;
	}
}
