.call-to-action-banner {
	position: relative;
	height: 80vh;
	min-height: 400px;
}

@media (--mq-from-xsmall) {
	.call-to-action-banner {
		height: 66.6666vh;
	}
}

@media (--mq-from-small) {
	.call-to-action-banner {
		min-height: 500px;
	}
}

@media (--mq-from-small) {
	.call-to-action-banner {
		min-height: 640px;
	}
}

@media (--mq-from-wide) {
	.call-to-action-banner {
		min-height: 750px;
	}
}

.call-to-action-banner__background {
	background-color: var(--color-blue-light);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.call-to-action-banner__background__caption {
	color: var(--color-white);
	font-family: var(--font-sans);
	font-size: 0.75rem;
	position: absolute;
	top: 1rem;
	right: 1rem;
}

.call-to-action-banner__content {
	position: absolute;
	bottom: 6.25vw;
	left: 0;
	width: 100%;
	z-index: 2;
}

@media (--mq-from-small) {
	.call-to-action-banner__content {
		bottom: 2rem;
	}
}

@media (--mq-from-medium) {
	.call-to-action-banner__content {
		bottom: 3rem;
	}
}

@media (--mq-from-wide) {
	.call-to-action-banner__content {
		bottom: 4rem;
	}
}
