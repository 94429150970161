.category-list {
	color: var(--color-black);
	display: block;
}

@media (min-width: 568px) {
	.category-list-group .category-list {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}

	.category-list-group .category-list:nth-child(1),
	.category-list-group .category-list:nth-child(2) {
		margin-bottom: 1.25rem;
	}
}

@media (min-width: 768px) {
	.category-list-group .category-list {
		flex-basis: 25%;
		flex-grow: 0;
		flex-shrink: 0;
	}

	.category-list-group .category-list:nth-child(1),
	.category-list-group .category-list:nth-child(2) {
		margin-bottom: 0;
	}
}

@media (min-width: 1024px) {
	.category-list-group .category-list {
		flex-basis: 25%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (max-width: 567px) {
	.category-list-group .category-list:not(:only-child):not(:first-child) {
		margin-top: 2.5rem;
	}
}

.category-list__header {
	font-family: var(--font-sans);
}

@media (max-width: 767px) {
	.category-list__header {
		display: flex;
		align-items: center;
	}
}

.category-list__header svg:not(.icon) {
	display: none;
}

.category-list__header .icon {
	width: 2.5rem;
	height: 2.5rem;
}

@media (min-width: 768px) {
	.category-list__header .icon {
		display: block;
	}
}

@media (min-width: 1024px) {
	.category-list__header .icon {
		width: 4rem;
		height: 4rem;
	}
}

.category-list__toggle {
	color: currentColor;
	flex-grow: 0;
	flex-shrink: 1;
	font-size: 1.0625rem;
	font-weight: 400;
	line-height: 1.3333;
	text-decoration: none;
}

@media (max-width: 767px) {
	.category-list__toggle {
		box-sizing: border-box;
		padding-left: 1.25rem;
	}
}

@media (min-width: 768px) {
	.category-list__toggle {
		display: block;
		margin-top: 1rem;
		margin-bottom: 0.5rem;
		pointer-events: none;
	}
}

@media (min-width: 1024px) {
	.category-list__toggle {
		font-size: 1.125rem;
		margin-top: 1.25rem;
		margin-bottom: 1rem;
	}
}

@media (min-width: 1380px) {
	.category-list__toggle {
		font-size: 1.25rem;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}

@media (max-width: 1023px) {
	.category-list__toggle:hover {
		color: var(--color-red);
	}
}

@media (min-width: 1024px) {
	.category-list__toggle:hover {
		color: var(--color-black);
		cursor: default;
	}
}

.category-list__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (max-width: 767px) {
	.category-list__list {
		margin-top: 1rem;
		overflow: hidden;
	}
}

@media (min-width: 568px) and (max-width: 1023px) {
	.category-list__list {
		font-size: 0.875rem;
	}
}

@media (min-width: 768px) and (max-width: 1379px) {
	.category-list__list {
		box-sizing: border-box;
		padding-right: 1rem;
	}
}

.category-list__item {
	margin: 0;
}

@media (max-width: 567px) {
	.category-list__item {
		box-sizing: border-box;
		float: left;
		overflow: hidden;
		padding-right: 1rem;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
	}
}

.category-list__link {
	color: currentColor;
	line-height: 1.75;
	text-decoration: none;
}

@media (min-width: 1380px) {
	.category-list__link {
		font-size: 1.125rem;
	}
}

.category-list__link:hover {
	color: var(--color-red);
}
