/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.slideshow {
	position: relative;
}

@media (max-width: 767px) {
	.slideshow {
		margin-right: -6.25vw;
		margin-left: -6.25vw;
		width: 100vw;
	}
}

.showcase .slideshow {
	margin: 0;
	position: static;
	width: auto;
}

.slideshow__wrapper {
	overflow: hidden;
	position: relative;
}

.showcase .slideshow__wrapper {
	position: static;
}

.slideshow__track {
	box-sizing: border-box;
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
	/* stylelint-disable-next-line declaration-property-value-no-unknown -- broken, must be fixed by a human */
	white-space: wrap;
}

mr-slideshow.is-resolved .slideshow__track {
	overflow: visible;
}

.slideshow:not(.slideshow--static) .slideshow__track {
	transition: transform 688ms cubic-bezier(0.65, 0, 0.265, 1);
	will-change: transform;
}

.slideshow__item {
	flex: 0 0 100%;
}

mr-slideshow[use-click="true"] .slideshow__item:hover {
	cursor: pointer;
}

.showcase .slideshow__item {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.showcase .slideshow__wrapper,
.showcase .slideshow__track,
.showcase .slideshow__item {
	height: 100%;
}

.slideshow__item__image {
	display: block;
	width: 100%;
	height: auto;
}

.slideshow__item__image:hover {
	cursor: pointer;
}

.slideshow__item__caption {
	color: var(--color-blue);
	display: inline-block;
	font-family: var(--font-sans);
	font-size: 0.75rem;
	margin-top: 1rem;
}

@media (max-width: 767px) {
	.slideshow__item__caption {
		box-sizing: border-box;
		padding-left: 6.25vw;
	}
}

.slideshow__pagination {
	color: var(--color-black);
	font-size: 0.875rem;
	line-height: 1;
	position: absolute;
	text-align: right;
	right: 0;
	bottom: 0;
	width: max-content;
}

@media (max-width: 767px) {
	.slideshow__pagination {
		right: 6.25vw;
	}
}

.slideshow__buttons {
	display: flex;
	justify-content: flex-end;
	position: absolute;
}

@media (max-width: 767px) {
	.slideshow__buttons {
		background-color: white;
		padding: 0 0.75rem 0.75rem 0;
		text-align: right;
		right: 0;
		transform: translateY(-100%);
		width: max-content;
	}

	.slideshow[data-aspect-ratio="1:1"] .slideshow__buttons {
		top: 100vw;
	}

	.slideshow[data-aspect-ratio="1:2"] .slideshow__buttons {
		top: 200vw;
	}

	.slideshow[data-aspect-ratio="2:1"] .slideshow__buttons {
		top: 50vw;
	}

	.slideshow[data-aspect-ratio="2:3"] .slideshow__buttons {
		top: 150vw;
	}

	.slideshow[data-aspect-ratio="3:2"] .slideshow__buttons {
		top: 66.6667vw;
	}

	.slideshow[data-aspect-ratio="9:16"] .slideshow__buttons {
		top: 177.777777778vw;
	}

	.slideshow[data-aspect-ratio="16:9"] .slideshow__buttons {
		top: 56.25vw;
	}
}

@media (min-width: 768px) {
	.slideshow__buttons {
		flex-wrap: wrap;
		top: 50%;
		right: calc(100% + 1rem);
		transform: translateY(-50%);
	}
}

@media (min-width: 1024px) {
	.slideshow__buttons {
		right: calc(100% + 2rem);
	}
}

.slideshow__button {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-blue);
	font-family: var(--font-serif);
	font-size: 0.875rem;
	outline: 0;
	padding: 0.75rem 0.75rem 0;
	text-align: right;
	text-decoration: none;
	transition: color 96ms linear;
}

@media (min-width: 768px) {
	.slideshow__button {
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		padding: 0.375rem 0.75rem;
		text-align: center;
	}
}

@media (hover: hover) {
	.slideshow__button:hover {
		color: var(--color-red);
		cursor: pointer;
	}
}

@media (max-width: 767px) {
	.slideshow__button:last-child {
		padding-right: 0;
	}
}

.slideshow__button:not(:only-child):not(:last-child) {
	position: relative;
}

.slideshow__button:not(:only-child):not(:last-child)::after {
	background-color: var(--color-blue);
	content: "";
	display: block;
	position: absolute;
}

@media (max-width: 767px) {
	.slideshow__button:not(:only-child):not(:last-child)::after {
		top: 0.75rem;
		right: 0;
		width: 0.0625rem;
		height: 1rem;
	}
}

@media (min-width: 768px) {
	.slideshow__button:not(:only-child):not(:last-child)::after {
		right: 30%;
		bottom: 0;
		width: 40%;
		height: 0.0625rem;
	}
}

.slideshow__button:active,
.slideshow__button:focus,
.slideshow__button:hover {
	box-shadow: none;
	outline: 0;
}

.slideshow__button.is-disabled {
	pointer-events: none;
}

.slideshow__navigation {
	font-size: 0.875rem;
	position: absolute;
	text-align: right;
	right: 0;
	bottom: 0;
}

@media (max-width: 767px) {
	.slideshow__navigation {
		right: 6.25vw;
	}
}

.slideshow__navigation button {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	outline: 0;
	padding: 0 0.25rem;
}

.slideshow__navigation button.is-active {
	text-decoration: underline;
}

.slideshow__navigation button:hover {
	cursor: pointer;
}
