.search-overlay {
	background-color: rgb(33 75 154 / 0.95);
	color: var(--color-white);
	display: none;
	opacity: 0;
	justify-content: center;
	align-items: center;
	position: fixed;
	text-align: center;
	top: 0;
	transition: opacity 256ms ease-in-out;
	width: 100%;
	height: 100%;
	z-index: 9999;
}

.search-overlay.is-active {
	display: flex;
}

.search-overlay.is-showing {
	opacity: 1;
	transition: opacity 256ms ease-in-out;
}

.search-overlay__form {
	width: 87.5%;
	max-width: 700px;
}

@media (--mq-from-small) {
	.search-overlay__form {
		width: 66.6666%;
	}
}

@media (--mq-from-wide) {
	.search-overlay__form {
		width: 50%;
	}
}

.search-overlay__form label {
	display: inline-block;
	font-family: var(--font-sans);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.33;
	margin-bottom: 1rem;
}

@media (--mq-from-small) {
	.search-overlay__form label {
		font-size: 2rem;
		margin-bottom: 2.5rem;
	}
}

@media (--mq-from-medium) {
	.search-overlay__form label {
		font-size: 2.5rem;
		margin-bottom: 3.75rem;
	}
}

@media (--mq-from-medium) and (--mq-up-to-wide) {
	.search-overlay__form label {
		box-sizing: border-box;
		padding: 0 20%;
	}
}

@media (--mq-from-wide) {
	.search-overlay__form label {
		font-size: 3.75rem;
		margin-bottom: 6rem;
	}
}

.search-overlay__form input {
	appearance: none;
	background: none;
	border: 0;
	border-bottom: 1px solid var(--color-white);
	border-radius: 0;
	box-shadow: none;
	color: currentColor;
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-weight: 400;
	line-height: 1.5rem;
	outline: 0;
	padding-right: 0;
	padding-left: 0;
	text-align: center;
	height: 1.5rem;
}

@media (--mq-from-small) {
	.search-overlay__form input {
		font-size: 1.5rem;
		line-height: 2rem;
		height: 2rem;
	}
}

@media (--mq-from-wide) {
	.search-overlay__form input {
		font-size: 2rem;
		line-height: 3.5rem;
		height: 3.5rem;
	}
}
