.image-group {
	width: 100%;
}

@media (--mq-up-to-small) {
	.image-group {
		margin-right: -6.25vw;
		margin-left: -6.25vw;
		width: 100vw;
	}
}

@media (--mq-from-xsmall) {
	.image-group {
		display: flex;
	}
}

@media (--mq-up-to-xsmall) {
	.image-group__item {
		margin-bottom: 1rem;
	}
}

@media (--mq-from-small) {
	.image-group__item {
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (--mq-up-to-xsmall) {
	.image-group__item:last-child {
		margin-bottom: 0;
	}
}

.image-group__image {
	display: block;
}

.image-group__caption {
	color: var(--color-blue);
	display: inline-block;
	font-family: var(--font-sans);
	font-size: 0.75rem;
	margin-top: 1rem;
}

@media (--mq-up-to-small) {
	.image-group__caption {
		box-sizing: border-box;
		padding-left: 6.25vw;
	}
}
