@media (max-width: 767px) {
	.grid {
		margin-right: -6.25vw;
		margin-left: -6.25vw;
		width: 100vw;
	}
}

@media (min-width: 568px) {
	.grid {
		display: flex;
		flex-wrap: wrap;
	}
}

@supports (display: grid) {
	@media (min-width: 568px) {
		.grid {
			display: grid;
			grid-row-gap: 2rem;
			grid-auto-rows: 1fr;
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media (min-width: 1024px) {
		.grid {
			grid-row-gap: 3rem;
			grid-template-columns: repeat(3, 1fr);
		}
	}

	@media (min-width: 1380px) {
		.grid {
			grid-row-gap: 4rem;
		}
	}

	@media (min-width: 1680px) {
		.grid {
			grid-row-gap: 4.5rem;
		}
	}

	@media (min-width: 568px) {
		.grid.grid--preview,
		.grid.grid--featured {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media (min-width: 568px) {
		.grid.grid--featured {
			grid-row-gap: 0;
		}
	}

	@media (min-width: 768px) {
		.grid.grid--small {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	@media (min-width: 1024px) {
		.grid.grid--small {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

.grid__item {
	margin-bottom: 2rem;
}

@media (min-width: 568px) {
	.grid__item {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 1024px) {
	.grid__item {
		flex-basis: 33.3333%;
		margin-bottom: 3rem;
	}
}

@media (min-width: 1380px) {
	.grid__item {
		margin-bottom: 4rem;
	}
}

@media (min-width: 1680px) {
	.grid__item {
		margin-bottom: 4.5rem;
	}
}

.grid--preview .grid__item.grid__item:not(:nth-child(4n + 2)),
.grid--preview .grid__item.grid__item:not(:nth-child(4n + 3)) {
	background-color: var(--color-blue-light);
}

.grid--preview .grid__item.grid__item:nth-child(4n + 2),
.grid--preview .grid__item.grid__item:nth-child(4n + 3) {
	background-color: var(--color-blue);
}

@media (min-width: 568px) {
	.grid--featured .grid__item,
	.grid--preview .grid__item {
		flex-basis: 50%;
		margin: 0;
	}
}

@supports (display: grid) {
	@media (min-width: 568px) {
		.grid__item {
			margin-bottom: 0;
		}
	}
}
