:root {
	--color-foreground: #000000;
	--color-background: #ffffff;
	--color-black: #000000;
	--color-blue: #214b9a;
	--color-blue-dark: #0b3585;
	--color-blue-lightened: #6ea0e1;
	--color-blue-light: #f1f8ff;
	--color-gray: #c4c4c4;
	--color-red: #ff544b;
	--color-white: #ffffff;
	--font-sans: "Neutrif Studio", "Helvetica Neue", sans-serif;
	--font-serif: "Lora", "Georgia", serif;
}
