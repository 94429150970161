.social-links {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: flex-start;
	align-items: center;
}

@media (--mq-up-to-medium) {
	.has-open-navigation .mobile-navigation .social-links {
		padding-top: 1rem;
	}
}

.social-links__item {
	display: block;
	margin: 0;
}

.social-links__item:not(:only-child):not(:first-child) {
	margin-left: 0.75rem;
}

.social-links__link {
	display: block;
	overflow: hidden;
	position: relative;
	width: 20px;
	height: 20px;
}

.social-links__link svg:not(.icon) {
	display: none;
}

.social-links__link .icon {
	fill: var(--color-blue);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: fill 96ms linear;
	width: 16px;
	height: 16px;
}

.social-links__link:hover .icon {
	fill: var(--color-red);
}
