.button {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	appearance: none;
	background-color: var(--color-blue);
	border: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-white);
	display: inline-block;
	font-family: var(--font-sans);
	font-size: 0.9375rem;
	font-weight: 600;
	line-height: 38px;
	outline: 0;
	padding: 0 1rem;
	text-align: center;
	text-decoration: none;
	transition: background-color 96ms linear;
	width: auto;
	min-width: 216px;
	height: 38px;
}

@media (--mq-from-small) {
	.button {
		line-height: 42px;
		padding: 0 1.5rem;
		height: 42px;
	}
}

@media (--mq-from-medium) {
	.button {
		font-size: 1rem;
		line-height: 54px;
		padding: 0 2rem;
		height: 54px;
	}
}

.button:hover {
	background-color: var(--color-red);
	color: var(--color-white);
	cursor: pointer;
}

.button.button--white {
	background-color: var(--color-white);
	color: var(--color-blue);
}

.button.button--white:hover,
.button.button--white:focus {
	background-color: var(--color-red);
	color: var(--color-white);
}

.button.button--disabled {
	background-color: var(--color-blue);
	color: var(--color-blue-lightened);
	pointer-events: none;
}

.button.button--disabled:hover,
.button.button--disabled:focus {
	cursor: default;
}
