.faq {
	display: block;
}

.faq__group.is-hidden {
	display: none;
}

.faq__group.is-hidden + .faq__group:not(.is-hidden) {
	padding-top: 2rem;
}

@media (--mq-from-small) {
	.faq__group.is-hidden + .faq__group:not(.is-hidden) {
		padding-top: 3rem;
	}
}

@media (--mq-from-medium) {
	.faq__group.is-hidden + .faq__group:not(.is-hidden) {
		padding-top: 4rem;
	}
}

@media (--mq-from-wide) {
	.faq__group.is-hidden + .faq__group:not(.is-hidden) {
		padding-top: 4.625rem;
	}
}

@media (--mq-from-large) {
	.faq__group.is-hidden + .faq__group:not(.is-hidden) {
		padding-top: 6.125rem;
	}
}
