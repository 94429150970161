.styleguide__navigation {
	display: grid;
	flex-wrap: wrap;
	grid-auto-rows: 1fr;
	grid-template-columns: 1fr;
	list-style: none;
	margin: 2rem 0;
	padding: 0;
}

@media (--mq-from-small) {
	.styleguide__navigation {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (--mq-from-medium) {
	.styleguide__navigation {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (--mq-from-wide) {
	.styleguide__navigation {
		grid-template-columns: repeat(5, 1fr);
	}
}

.styleguide__navigation li {
	margin-right: 2rem;
	padding-left: 2rem;
	position: relative;
}

.styleguide__navigation li::before {
	content: "☞";
	font-size: 1.5rem;
	position: absolute;
	top: -3px;
	left: 0;
}

.styleguide__navigation a {
	color: blue;
	font-family: var(--font-sans);
	font-size: 1rem;
}

.styleguide__navigation a:hover,
.styleguide__navigation a:focus {
	color: red;
}

.styleguide__item {
	padding: 2rem 0;
}

@media (--mq-from-small) {
	.styleguide__item {
		padding: 3rem 0;
	}
}

@media (--mq-from-medium) {
	.styleguide__item {
		padding: 4rem 0;
	}
}

@media (--mq-from-wide) {
	.styleguide__item {
		padding: 5rem 0;
	}
}

.styleguide__item:nth-of-type(odd) {
	background-color: WhiteSmoke;
}

.styleguide__title {
	color: blue;
	font-size: 0.875rem;
	letter-spacing: 1px;
	margin-bottom: 2rem;
	text-align: center;
	text-transform: uppercase;
}

@media (--mq-from-medium) {
	.styleguide .mobile-only {
		display: none;
	}
}

@media (--mq-up-to-medium) {
	.styleguide .desktop-only {
		display: none;
	}
}
