.numbered-content {
	color: var(--color-blue);
	counter-reset: numbered-content;
	list-style: none;
	margin: 0;
	padding: 0;
}

.numbered-content__item {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin-bottom: 2rem;
	position: relative;
}

@media (--mq-from-small) {
	.numbered-content__item {
		margin-bottom: 3rem;
	}
}

@media (--mq-from-medium) {
	.numbered-content__item {
		margin-bottom: 4rem;
	}
}

@media (--mq-from-wide) {
	.numbered-content__item {
		margin-bottom: 5rem;
	}
}

@media (--mq-from-large) {
	.numbered-content__item {
		margin-bottom: 6.25rem;
	}
}

.numbered-content__item::before {
	content: counter(numbered-content);
	counter-increment: numbered-content;
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-weight: 700;
	line-height: 1.3333;
	position: absolute;
	top: 0;
	left: 0;
}

@media (--mq-from-small) {
	.numbered-content__item::before {
		font-size: 1.5rem;
		left: -3rem;
	}
}

@media (--mq-from-medium) {
	.numbered-content__item::before {
		font-size: 1.625rem;
		left: -4rem;
	}
}

@media (min-width: 1280px) {
	.numbered-content__item::before {
		left: -5rem;
	}
}

@media (--mq-from-wide) {
	.numbered-content__item::before {
		font-size: 2.25rem;
		left: -7rem;
	}
}

@media (--mq-from-large) {
	.numbered-content__item::before {
		left: -9rem;
	}
}

.numbered-content__item:nth-child(-n + 9)::before {
	content: "0" counter(numbered-content);
}

.numbered-content__item__title {
	font-weight: 400;
}

@media (--mq-up-to-small) {
	.numbered-content__item__title {
		padding-left: 2rem;
	}
}

@media (--mq-from-wide) {
	.numbered-content__item__title {
		font-size: 2.25rem;
	}
}
