.side-navigation {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--font-sans);
	font-size: 1.125rem;
}

@media (--mq-from-medium) and (--mq-up-to-wide) {
	.side-navigation {
		font-size: 1rem;
	}
}

.side-navigation__title {
	color: var(--color-gray);
	font-size: inherit;
}

@media (--mq-up-to-medium) {
	.side-navigation__title {
		margin-bottom: 1rem;
	}
}

.side-navigation__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.side-navigation__item {
	margin: 0;
}

@media (--mq-from-medium) {
	.page-header__sidebar .side-navigation__item {
		width: 18.5vw;
	}
}

@media (--mq-from-wide) {
	.page-header__sidebar .side-navigation__item {
		max-width: 300px;
	}
}

.side-navigation__link {
	color: var(--color-blue);
	display: block;
	line-height: 1.7777;
	overflow: hidden;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@media (--mq-up-to-medium) {
	.side-navigation__link {
		font-size: 1rem;
	}
}

@media (--mq-from-medium) {
}

.side-navigation__link.side-navigation__link--active {
	box-sizing: border-box;
	padding-left: 1.25rem;
	position: relative;
}

.side-navigation__link.side-navigation__link--active::before {
	background-color: var(--color-blue);
	border-radius: 5px;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	transition: background-color 96ms linear;
	width: 5px;
	height: 5px;
}

.side-navigation__link:hover {
	color: var(--color-red);
}

.side-navigation__link.side-navigation__link--active:hover::before {
	background-color: var(--color-red);
}

.post-meta--colored .side-navigation__link {
	color: var(--color-white);
}

.post-meta--colored .side-navigation__link::before {
	background-color: var(--color-white);
}
