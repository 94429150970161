.accordion {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	border-bottom: 1px solid #e1ecf8;
}

.accordion.accordion--choices {
	border-bottom: 0;
}

.accordion .accordion {
	border-bottom: 0;
}

.accordion,
.accordion dt,
.accordion dd {
	color: var(--color-black);
	font-family: var(--font-serif);
	margin: 0;
	padding: 0;
}

.accordion dt {
	line-height: 1;
}

.accordion__toggle {
	appearance: none;
	background: none;
	border-top: 1px solid #e1ecf8;
	border-right: 0;
	border-bottom: 0;
	border-left: 0;
	box-shadow: none;
	font-family: inherit;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.33;
	margin: 0;
	padding: 1rem 1rem 1rem 0;
	position: relative;
	text-align: left;
	transition: color 96ms linear;
	width: 100%;
}

@media (min-width: 768px) {
	.accordion__toggle {
		font-size: 1.0625rem;
	}
}

@media (min-width: 1024px) {
	.accordion__toggle {
		font-size: 1.125rem;
		padding: 1.5rem 1.5rem 1.5rem 0;
	}
}

.accordion--choices .accordion__toggle {
	border-top-color: var(--color-blue);
	font-family: var(--font-sans);
	font-size: 1.0625rem;
	font-weight: 400;
}

@media (max-width: 767px) {
	.accordion--choices .accordion__toggle {
		font-size: 1.125rem;
		padding: 1rem 0 1rem 2rem;
	}
}

@media (min-width: 768px) {
	.accordion--choices .accordion__toggle {
		font-size: 1.5rem;
	}
}

@media (min-width: 1024px) {
	.accordion--choices .accordion__toggle {
		font-size: 1.875rem;
	}
}

.accordion--choices dt:first-child > .accordion__toggle,
.accordion__sub-accordion .accordion__toggle {
	border-top: 0;
}

.accordion__sub-accordion .accordion__toggle {
	font-family: var(--font-serif);
	font-size: 1rem;
	font-weight: 400;
	margin-top: 0.5rem;
	padding: 0 0 0 2rem;
}

@media (min-width: 768px) {
	.accordion__sub-accordion .accordion__toggle {
		font-size: 1.125rem;
	}
}

.accordion__sub-accordion dt:not(:only-child):not(:first-child) > .accordion__toggle {
	margin-top: 1rem;
}

@media (min-width: 1024px) {
	.accordion__sub-accordion dt:not(:only-child):not(:first-child) > .accordion__toggle {
		margin-top: 2rem;
	}
}

mr-accordion.is-resolved .accordion__toggle[aria-expanded="true"] {
	color: var(--color-red);
}

.accordion__toggle:hover {
	color: var(--color-red);
	cursor: pointer;
}

mr-accordion.is-resolved .accordion__toggle[aria-expanded="false"]:not(:hover) {
	color: var(--color-blue);
}

mr-accordion.is-resolved .accordion__toggle::after {
	content: "+";
	display: block;
	line-height: 1;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	transition: transform 96ms linear;
}

mr-accordion.is-resolved .accordion__toggle[aria-expanded="true"]::after {
	content: "-";
}

mr-accordion.is-resolved .accordion--choices .accordion__toggle::after {
	border: 2px solid var(--color-blue);
	border-radius: 100%;
	content: "";
	display: block;
	left: 0;
	transition: border-color 96ms linear;
	width: 0.75rem;
	height: 0.75rem;
}

@media (min-width: 768px) {
	mr-accordion.is-resolved .accordion--choices .accordion__toggle::after {
		left: -3rem;
	}
}

@media (min-width: 1280px) {
	mr-accordion.is-resolved .accordion--choices .accordion__toggle::after {
		left: -4.5rem;
	}
}

@media (min-width: 1380px) {
	mr-accordion.is-resolved .accordion--choices .accordion__toggle::after {
		left: -6rem;
	}
}

@media (min-width: 1680px) {
	mr-accordion.is-resolved .accordion--choices .accordion__toggle::after {
		left: -7rem;
	}
}

mr-accordion.is-resolved .accordion--choices.accordion__sub-accordion .accordion__toggle::after {
	left: 0;
}

mr-accordion.is-resolved .accordion--choices .accordion__toggle[aria-expanded="true"]::after {
	border-color: var(--color-red);
	content: "";
}

mr-accordion.is-resolved .accordion--choices .accordion__toggle::before {
	background-color: var(--color-red);
	border-radius: 100%;
	content: "";
	display: block;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 0.3125rem;
	transform: translateY(-50%);
	transition: opacity 96ms linear;
	width: 0.375rem;
	height: 0.375rem;
}

@media (min-width: 768px) {
	mr-accordion.is-resolved .accordion--choices .accordion__toggle::before {
		left: -2.6875rem;
	}
}

@media (min-width: 1280px) {
	mr-accordion.is-resolved .accordion--choices .accordion__toggle::before {
		left: -4.1875rem;
	}
}

@media (min-width: 1380px) {
	mr-accordion.is-resolved .accordion--choices .accordion__toggle::before {
		left: -5.6875rem;
	}
}

@media (min-width: 1680px) {
	mr-accordion.is-resolved .accordion--choices .accordion__toggle::before {
		left: -6.6875rem;
	}
}

mr-accordion.is-resolved .accordion--choices.accordion__sub-accordion .accordion__toggle::before {
	left: 0.3125rem;
}

mr-accordion.is-resolved .accordion--choices .accordion__toggle[aria-expanded="true"]::before {
	opacity: 1;
}

.accordion__toggle:active,
.accordion__toggle:focus,
.accordion__toggle:hover {
	box-shadow: none;
	outline: 0;
}

.accordion__content {
	color: inherit;
}

.accordion__sub-accordion .accordion__content {
	box-sizing: border-box;
	padding-left: 2rem;
}

@media (max-width: 767px) {
	.accordion--choices .accordion__content:not(.accordion__content--has-sub-accordion) {
		padding-left: 2rem;
	}
}

.accordion--choices .accordion__content p {
	color: var(--color-blue);
}

@media (min-width: 768px) {
	.accordion--choices .accordion__content p {
		font-size: 1.125rem;
	}
}

.accordion__content ul:only-child {
	margin: 0;
}

.accordion__content a:not(.button):hover {
	color: var(--color-red);
}

mr-accordion.is-resolved .accordion__content:not([hidden]) {
	padding-bottom: 1rem;
}

@media (min-width: 1024px) {
	mr-accordion.is-resolved .accordion__content:not([hidden]) {
		padding-top: 0.75rem;
		padding-bottom: 2.5rem;
	}
}

@media (min-width: 1024px) {
	mr-accordion.is-resolved .accordion--choices .accordion__content:not([hidden]) {
		padding-top: 0;
		padding-bottom: 2.5rem;
	}
}

mr-accordion.is-resolved .accordion__sub-accordion .accordion__content:not([hidden]) {
	padding-top: 0.75rem;
	padding-bottom: 0;
}

@media (min-width: 1024px) {
	mr-accordion.is-resolved .accordion__sub-accordion .accordion__content:not([hidden]) {
		padding-top: 2rem;
	}
}

mr-accordion:not(.is-resolved) .accordion__content {
	display: block;
}
