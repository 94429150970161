.call-to-action {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-blue-light);
	box-sizing: border-box;
	color: var(--color-blue);
	display: block;
	text-decoration: none;
	transition:
		opacity 96ms linear,
		background-color 96ms linear,
		color 96ms linear;
}

.call-to-action-group .call-to-action {
	flex-grow: 1;
}

@media (min-width: 768px) {
	.call-to-action-group .call-to-action:not(:only-child) {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

/*
	 * Dark variation
	 */

.call-to-action.call-to-action--dark {
	background-color: var(--color-blue);
	color: var(--color-white);
}

/*
	 * Hover states - basic
	 */

.call-to-action.call-to-action--link:hover {
	background-color: var(--color-blue);
	color: var(--color-white);
}

.call-to-action.call-to-action--dark.call-to-action--link:hover {
	background-color: var(--color-blue-light);
	color: var(--color-blue);
}

/*
	 * CTA group items
	 */

.call-to-action-group .call-to-action.call-to-action--link:nth-child(even) {
	background-color: var(--color-blue);
	color: var(--color-white);
}

.call-to-action-group .call-to-action.call-to-action--link:nth-child(odd):hover {
	background-color: #dceaf9;
	color: var(--color-blue);
}

.call-to-action-group .call-to-action.call-to-action--link:nth-child(even):hover {
	background-color: #0e3888;
	color: var(--color-white);
}

/*
	 * Preview grid items
	 */

.grid--preview .grid__item:nth-child(4n + 2) .call-to-action.call-to-action--link,
.grid--preview .grid__item:nth-child(4n + 3) .call-to-action.call-to-action--link {
	background-color: var(--color-blue);
	color: var(--color-white);
}

.grid--preview .grid__item:nth-child(4n + 1) .call-to-action.call-to-action--link:hover,
.grid--preview .grid__item:nth-child(4n + 4) .call-to-action.call-to-action--link:hover {
	background-color: #dceaf9;
	color: var(--color-blue);
}

.grid--preview .grid__item:nth-child(4n + 2) .call-to-action.call-to-action--link:hover,
.grid--preview .grid__item:nth-child(4n + 3) .call-to-action.call-to-action--link:hover {
	background-color: #0e3888;
	color: var(--color-white);
}

/*
	 * Show / hide banner module
	 */

.call-to-action-banner .call-to-action-group:hover .call-to-action:not(:hover) {
	opacity: 0;
}

.call-to-action-banner .call-to-action-group .call-to-action.call-to-action--link:nth-child(odd):hover {
	background-color: var(--color-blue);
	color: var(--color-white);
}

.call-to-action-banner .call-to-action-group .call-to-action.call-to-action--link:nth-child(even):hover {
	background-color: var(--color-white);
	color: var(--color-blue);
}

.grid .call-to-action {
	height: 100%;
}

.call-to-action__image-container {
	overflow: hidden;
	padding-bottom: 40%;
	position: relative;
}

.call-to-action__image {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	transform: scale(1);
	transition: transform 1200ms linear;
	width: 100%;
	height: 100%;
}

.call-to-action--link:hover .call-to-action__image {
	transform: scale(1.1);
	transition: transform 9600ms linear;
}

.call-to-action__inner-wrapper {
	padding: 1rem;
}

@media (min-width: 768px) {
	.call-to-action__inner-wrapper {
		padding: 2rem;
	}
}

@media (min-width: 1024px) {
	.call-to-action__inner-wrapper {
		padding: 3rem;
	}
}

@media (min-width: 1380px) {
	.call-to-action__inner-wrapper {
		padding: 4rem;
	}
}

.call-to-action__header {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.call-to-action__header svg:not(.icon) {
	display: none;
}

.call-to-action--link .call-to-action__header {
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.call-to-action__header .icon {
	fill: var(--color-blue);
	flex-basis: 24px;
	flex-grow: 0;
	flex-shrink: 0;
	transition: fill 96ms linear;
	width: 14px;
	height: 14px;
}

.call-to-action--info .call-to-action__header .icon {
	width: 24px;
	height: 24px;
}

@media (min-width: 1024px) {
	.call-to-action--info .call-to-action__header .icon {
		padding-top: 0.25rem;
	}
}

.call-to-action--link .call-to-action__header .icon {
	padding-top: 0.25rem;
}

@media (min-width: 768px) {
	.call-to-action--link .call-to-action__header .icon {
		padding-top: 0.5rem;
	}
}

@media (min-width: 1024px) {
	.call-to-action--link .call-to-action__header .icon {
		padding-top: 0.75rem;
	}
}

/*
		 * Dark variation
		 */

.call-to-action--dark .call-to-action__header .icon {
	fill: var(--color-white);
}

/*
		 * Hover states - basic
		 */

.call-to-action--link:hover .call-to-action__header .icon {
	fill: var(--color-white);
}

.call-to-action--dark.call-to-action--link:hover .call-to-action__header .icon {
	fill: var(--color-blue);
}

/*
		 * CTA group items
		 */

.call-to-action-group .call-to-action--link:nth-child(even) .call-to-action__header .icon {
	fill: var(--color-white);
}

.call-to-action-group .call-to-action--link:nth-child(odd):hover .call-to-action__header .icon {
	fill: var(--color-blue);
}

.call-to-action-group .call-to-action--link:nth-child(even):hover .call-to-action__header .icon {
	fill: var(--color-white);
}

/*
		 * Preview grid items
		 */

.grid--preview .grid__item:nth-child(4n + 2) .call-to-action--link .call-to-action__header .icon,
.grid--preview .grid__item:nth-child(4n + 3) .call-to-action--link .call-to-action__header .icon {
	fill: var(--color-white);
}

.grid--preview .grid__item:nth-child(4n + 1) .call-to-action--link:hover .call-to-action__header .icon,
.grid--preview .grid__item:nth-child(4n + 4) .call-to-action--link:hover .call-to-action__header .icon {
	fill: var(--color-blue);
}

.grid--preview .grid__item:nth-child(4n + 2) .call-to-action--link:hover .call-to-action__header .icon,
.grid--preview .grid__item:nth-child(4n + 3) .call-to-action--link:hover .call-to-action__header .icon {
	fill: var(--color-white);
}

/*
		 * Show / hide banner module
		 */

.call-to-action-banner .call-to-action-group .call-to-action--link:nth-child(odd):hover .call-to-action__header .icon {
	fill: var(--color-white);
}

.call-to-action-banner .call-to-action-group .call-to-action--link:nth-child(even):hover .call-to-action__header .icon {
	fill: var(--color-blue);
}

.call-to-action__title {
	box-sizing: border-box;
	display: inline-block;
	flex-grow: 0;
	flex-shrink: 1;
	margin-top: 0;
	padding-left: 1rem;
}

@media (min-width: 1380px) {
	.call-to-action__title {
		font-size: 1.875rem;
	}
}

.call-to-action--link .call-to-action__title {
	padding-right: 0.75rem;
	padding-left: 0;
}

@media (min-width: 1024px) {
	.call-to-action--info .call-to-action__title {
		flex-grow: 1;
	}
}

.grid--preview .call-to-action__header:only-child .call-to-action__title {
	margin-bottom: 0;
}

@media (min-width: 1024px) {
	.call-to-action__content {
		box-sizing: border-box;
		display: inline-block;
	}
}
