@media (--mq-from-medium) {
	.post-meta {
		display: none;
	}
}

.post-meta::after {
	content: none;
}

.post-meta.post-meta--colored {
	background-color: var(--color-blue);
	color: var(--color-white);
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	transform: translateY(3px);
}

@media (--mq-from-small) {
	.post-meta.post-meta--colored {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}
