.bordered-section {
	padding-top: calc(2rem + 2px);
	padding-bottom: calc(2rem + 2px);
	position: relative;
}

@media (min-width: 768px) {
	.bordered-section {
		padding-top: calc(3rem + 3px);
		padding-bottom: calc(3rem + 3px);
	}
}

@media (min-width: 1024px) {
	.bordered-section {
		padding-top: calc(4rem + 3px);
		padding-bottom: calc(4rem + 3px);
	}
}

@media (min-width: 1380px) {
	.bordered-section {
		padding-top: calc(4.625rem + 3px);
		padding-bottom: calc(4.625rem + 3px);
	}
}

@media (min-width: 1680px) {
	.bordered-section {
		padding-top: calc(6.125rem + 3px);
		padding-bottom: calc(6.125rem + 3px);
	}
}

.bordered-section::before,
.bordered-section::after {
	background-image: url("../images/dot.svg");
	background-repeat: repeat-x;
	background-size: 7px 2px;
	content: "";
	position: absolute;
	left: 0;
	width: 100%;
	height: 2px;
}

@media (min-width: 768px) {
	.bordered-section::before,
	.bordered-section::after {
		background-size: 10px 3px;
		height: 3px;
	}
}

.bordered-section::before {
	top: 0;
}

.bordered-section::after {
	bottom: 0;
}

.bordered-section.bordered-section--bottom {
	padding-top: 0;
}

.bordered-section.bordered-section--bottom::before {
	content: none;
	display: none;
}

.bordered-section.bordered-section--top {
	padding-bottom: 0;
}

.bordered-section.bordered-section--top::after {
	content: none;
	display: none;
}

@media (min-width: 1024px) {
	.site-footer .bordered-section {
		padding-bottom: 0;
	}
}

@media (min-width: 1024px) {
	.site-footer .bordered-section::after {
		content: none;
		display: none;
	}
}

.bordered-section + .bordered-section::before,
.bordered-section + .site-footer .bordered-section::before {
	content: none;
	display: none;
}

.bordered-section__title {
	color: var(--color-blue);
	margin-bottom: 1.75rem;
	text-align: center;
}

@media (min-width: 768px) {
	.bordered-section__title {
		margin-bottom: 2.75rem;
	}
}

@media (min-width: 1024px) {
	.bordered-section__title {
		margin-bottom: 3.75rem;
	}
}

@media (min-width: 1380px) {
	.bordered-section__title {
		margin-bottom: 5.125rem;
	}
}

.bordered-section__actions {
	display: flex;
	flex-wrap: wrap;
	margin-top: 1.75rem;
	margin-bottom: 0;
	justify-content: center;
	text-align: center;
	width: 100%;
}

@media (min-width: 768px) {
	.bordered-section__actions {
		margin-top: 2.75rem;
	}
}

@media (min-width: 1024px) {
	.bordered-section__actions {
		margin-top: 3.75rem;
	}
}

@media (min-width: 1380px) {
	.bordered-section__actions {
		margin-top: 5.125rem;
	}
}

@media (max-width: 767px) {
	.bordered-section__actions__item:not(:only-child):not(:first-child) {
		margin-top: 1rem;
		margin-bottom: 0;
	}
}

@media (min-width: 768px) {
	.bordered-section__actions__item:not(:only-child) {
		margin: 0 1rem;
	}
}
