.site-footer {
	background-color: var(--color-white);
}

@media (--mq-from-medium) {
	.site-footer__accordion-navigation {
		margin-bottom: 3rem;
	}
}

@media (--mq-from-wide) {
	.site-footer__accordion-navigation {
		margin-bottom: 4rem;
	}
}

@media (--mq-from-large) {
	.site-footer__accordion-navigation {
		margin-bottom: 5.125rem;
	}
}

.site-footer__meta-navigation {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

@media (--mq-from-small) {
	.site-footer__meta-navigation {
		display: flex;
		overflow: hidden;
		justify-content: space-between;
	}
}

@media (--mq-from-medium) {
	.site-footer__meta-navigation {
		padding-top: 0;
		padding-bottom: 2rem;
	}
}

@media (--mq-from-wide) {
	.site-footer__meta-navigation {
		padding-bottom: 3rem;
	}
}
