.mobile-navigation {
	-webkit-overflow-scrolling: touch;
	background-color: var(--color-white);
	display: none;
	overflow-x: hidden;
	overflow-y: scroll;
	position: relative;
	width: calc(100% - 2.875rem);
	height: calc(100vh - 3.1875rem);
	z-index: 98;
}

@media (--mq-up-to-medium) {
	.has-open-navigation .mobile-navigation {
		display: block;
	}
}

.mobile-navigation__wrapper {
	width: 100%;
	min-height: 100%;
}

.mobile-navigation__group {
	box-sizing: border-box;
	padding: 6.25vw 2rem 6.25vw 6.25vw;
	position: relative;
}

@media (--mq-from-small) {
	.mobile-navigation__group {
		padding: 3.75vw 2rem 3.75vw 3.75vw;
	}
}

.mobile-navigation__group:not(:only-child):not(:last-child)::after {
	background-image: url("../images/dot.svg");
	background-repeat: repeat-x;
	background-size: 7px 2px;
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
}

@media (--mq-from-small) {
	.mobile-navigation__group:not(:only-child):not(:last-child)::after {
		background-size: 10px 3px;
		height: 3px;
	}
}
