.year-pagination {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-blue);
	display: flex;
	flex-wrap: wrap;
	font-family: var(--font-sans);
	justify-content: center;
}

.year-pagination__item {
	flex-basis: 100px;
	flex-grow: 1;
	flex-shrink: 1;
}

@media (min-width: 1024px) {
	.year-pagination__item {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.year-pagination__item input {
	display: none;
}

.year-pagination__link {
	background-color: var(--color-blue);
	box-sizing: border-box;
	color: var(--color-white);
	display: block;
	padding: 1rem;
	text-align: center;
	text-decoration: none;
	transition:
		background-color 96ms linear,
		color 96ms linear;
}

@media (min-width: 768px) {
	.year-pagination__link {
		font-size: 1.125rem;
		padding: 1rem 2rem;
	}
}

@media (min-width: 1380px) {
	.year-pagination__link {
		font-size: 1.25rem;
		padding: 1.5rem 3.5rem;
	}
}

.year-pagination__link:hover,
.year-pagination__link.year-pagination__link--active {
	background-color: #d4e7fe;
	color: var(--color-blue);
	cursor: pointer;
}

*.year-pagination__item:first-of-type .year-pagination__link,
*.year-pagination__item:last-of-type .year-pagination__link {
	position: relative;
}

*.year-pagination__item:first-of-type .year-pagination__link::after,
*.year-pagination__item:last-of-type .year-pagination__link::after {
	font-family: var(--font-serif);
	position: absolute;
	top: 0.875rem;
}

@media (min-width: 1380px) {
	*.year-pagination__item:first-of-type .year-pagination__link::after,
	*.year-pagination__item:last-of-type .year-pagination__link::after {
		top: 1.3125rem;
	}
}

*.year-pagination__item:first-of-type .year-pagination__link::after {
	content: "‹";
	left: 1rem;
}

*.year-pagination__item:last-of-type .year-pagination__link::after {
	content: "›";
	right: 1rem;
}

.year-pagination__submit {
	display: none;
}
