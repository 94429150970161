.primary-navigation__list {
	color: var(--color-blue);
	display: flex;
	font-family: var(--font-sans);
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
	align-items: center;
}

.primary-navigation__item {
	margin: 0;
}

.primary-navigation__link {
	color: currentColor;
	display: block;
	font-size: 1.125rem;
	padding: 0 0.5rem;
	text-decoration: none;
	transition: color 96ms linear;
}

@media (--mq-from-wide) {
	.primary-navigation__link {
		font-size: 1.375rem;
		padding: 0 1rem;
	}
}

.primary-navigation__link:hover,
.primary-navigation__link.primary-navigation__link--active {
	color: var(--color-red);
}

.is-scrolled .primary-navigation__link {
	font-size: 1rem;
}

@media (--mq-from-wide) {
	.is-scrolled .primary-navigation__link {
		font-size: 1.125rem;
		padding: 0 1.625rem;
	}
}
