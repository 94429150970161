.meta-list {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: block;
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-weight: 400;
	margin: 0;
	overflow: hidden;
	padding: 0;
	max-width: 200px;
}

@media (--mq-from-wide) {
	.meta-list {
		max-width: 250px;
	}
}
