.l-404 {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-blue);
	color: var(--color-white);
	display: flex;
	font-family: var(--font-sans);
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	min-height: 100vh;
}

@media (--mq-up-to-small) {
	.l-404 {
		margin-top: -3.1875rem;
	}
}

.l-404__intro {
	font-size: 1.125rem;
}

.l-404__logo {
	display: block;
	margin: 0 auto 2rem;
	position: relative;
	width: 150px;
	height: 26px;
}

@media (--mq-from-small) {
	.l-404__logo {
		margin-bottom: 3rem;
	}
}

@media (--mq-from-medium) {
	.l-404__logo {
		margin-bottom: 4rem;
	}
}

@media (--mq-from-wide) {
	.l-404__logo {
		margin-bottom: 4.625rem;
		width: 200px;
		height: 35px;
	}
}

@media (--mq-from-large) {
	.l-404__logo {
		margin-bottom: 6.125rem;
		width: 250px;
		height: 44px;
	}
}

.l-404__logo svg:not(.icon) {
	display: none;
}

.l-404__logo .icon {
	display: block;
	fill: var(--color-red);
	position: absolute;
	transition: fill 96ms linear;
	width: 100%;
	height: 100%;
}

@media (--mq-from-medium) {
	.l-404__logo:hover .icon {
		cursor: pointer;
		fill: var(--color-white);
	}
}
