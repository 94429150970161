.hero {
	background-color: var(--color-blue);
	display: block;
	overflow: hidden;
	position: relative;
}

@media (max-width: 1023px) {
	.hero.hero--fullscreen {
		background-color: var(--color-white);
	}
}

.hero__track {
	box-sizing: border-box;
	display: flex;
	flex-wrap: nowrap;
	overflow: visible;
	position: relative;
	transition: transform 688ms cubic-bezier(0.65, 0, 0.265, 1);
	white-space: normal;
	will-change: transform;
}

@media (min-width: 1024px) {
	.hero__track {
		transition: transform 800ms cubic-bezier(0.65, 0, 0.365, 1);
		height: 66.6666vh;
	}
}

@media (min-width: 1380px) and (min-height: 860px) {
	.hero__track {
		min-height: 640px;
	}
}

.hero--fullscreen .hero__track {
	min-height: calc(100vh - 50px);
}

@media (min-width: 768px) {
	.hero--fullscreen .hero__track {
		min-height: calc(100vh - 58px);
	}
}

@media (min-width: 1024px) {
	.hero--fullscreen .hero__track {
		min-height: calc(100vh - 138px);
	}
}

@media (min-width: 1380px) {
	.hero--fullscreen .hero__track {
		min-height: calc(100vh - 160px);
	}
}

@media (max-width: 1023px) {
	.hero--text .hero__track {
		height: auto;
		min-height: 0;
	}
}

.hero__item {
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	position: relative;
}

.hero__background {
	position: relative;
}

@media (min-width: 1024px) {
	.hero__background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

@media (max-width: 1023px) {
	.hero--text .hero__background {
		display: none;
	}
}

.hero__background__image {
	display: block;
	width: 100%;
}

@media (min-width: 1024px) {
	.hero__background__image {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: auto;
		min-width: 100%;
		max-width: none;
		height: auto;
		min-height: 100%;
	}
}

@supports (object-fit: cover) {
	.hero__background__image {
		object-fit: cover;
		object-position: center;
		position: relative;
		transform: none;
		width: 100%;
		height: 66.6666vw;
	}

	@media (min-width: 1024px) {
		.hero__background__image {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
		}
	}

	@media (max-height: 460px) {
		.hero--fullscreen .hero__background__image {
			height: 50vh;
		}
	}

	@media (min-height: 461px) and (max-width: 767px) {
		.hero--fullscreen .hero__background__image {
			height: 60vh;
		}
	}

	@media (min-width: 768px) and (max-width: 1023px) {
		.hero--fullscreen .hero__background__image {
			height: 70vh;
		}
	}
}

.hero__background__caption {
	font-family: var(--font-sans);
	font-size: 0.75rem;
}

@media (max-width: 1023px) {
	.hero__background__caption {
		background-color: var(--color-white);
		box-sizing: border-box;
		color: var(--color-blue);
		padding: 1rem 33.3333% 1rem 6.25%;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.hero__background__caption {
		padding-left: 3.75%;
	}
}

@media (min-width: 1024px) {
	.hero__background__caption {
		color: var(--color-white);
		position: absolute;
		right: 3.75%;
		bottom: 1rem;
	}
}

@media (min-width: 1806px) {
	.hero__background__caption {
		right: calc((100vw - 1680px) / 2);
	}
}

@media (max-width: 1023px) {
	.hero__content {
		box-sizing: border-box;
		display: flex;
		flex-grow: 0;
		flex-shrink: 0;
		flex-direction: column;
		justify-content: flex-end;
		z-index: 1;
	}
}

@media (max-width: 1023px) {
	.hero__background + .hero__content {
		background-color: var(--color-white);
	}
}

@media (max-width: 1023px) {
	.hero__content:first-child {
		height: 66.6666vw;
	}
}

@media (max-height: 460px) {
	.hero--fullscreen .hero__content:first-child {
		height: 50vh;
	}
}

@media (min-height: 461px) and (max-width: 767px) {
	.hero--fullscreen .hero__content:first-child {
		height: 60vh;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.hero--fullscreen .hero__content:first-child {
		height: 70vh;
	}
}

@media (max-width: 767px) {
	.hero__content {
		padding-top: 0.25rem;
		padding-bottom: 1.25rem;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.hero__content {
		padding-top: 1rem;
		padding-bottom: 2rem;
	}
}

@media (min-width: 1024px) {
	.hero__content {
		padding-bottom: 6.25rem;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 2;
	}
}

.hero--text .hero__content {
	background-color: var(--color-blue);
}

.hero__content__title {
	color: var(--color-blue);
	display: inline-block;
	font-weight: 400;
	margin-bottom: 0;
}

@media (max-width: 1023px) {
	.hero__content:first-child .hero__content__title {
		color: var(--color-white);
	}
}

@media (max-width: 767px) {
	.hero__content__title {
		font-size: 1.25rem;
	}
}

@media (min-width: 768px) {
	.hero__content__title {
		padding-right: 25%;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.hero__content__title {
		padding-top: 1rem;
	}
}

@media (min-width: 1024px) {
	.hero__content__title {
		color: var(--color-blue);
		padding-right: 40%;
	}
}

@media (min-width: 1380px) {
	.hero__content__title {
		padding-right: 50%;
	}
}

@media (min-width: 1024px) {
	.hero--light .hero__content__title,
	.hero--fullscreen .hero__content__title {
		color: var(--color-white);
	}
}

.hero--text .hero__content__title {
	color: var(--color-white);
}

.hero__item--has-light-background .hero__content__title {
	color: var(--color-blue);
}

.hero__content__actions {
	display: flex;
	flex-wrap: wrap;
	margin-top: 2rem;
}

@media (min-width: 1380px) {
	.hero__content__actions {
		margin-top: 3rem;
	}
}

@media (max-width: 567px) {
	.hero__content__button {
		width: 100%;
	}
}

@media (max-width: 567px) {
	.hero__content__button:not(:only-child):not(:last-child) {
		margin-bottom: 1rem;
	}
}

@media (min-width: 568px) {
	.hero__content__button:not(:only-child):not(:last-child) {
		margin-right: 1rem;
	}
}

@media (min-width: 1380px) {
	.hero__content__button:not(:only-child):not(:last-child) {
		margin-right: 1.5rem;
	}
}

@media (min-width: 1024px) {
	.hero--light .hero__content__button {
		background-color: var(--color-white);
		color: var(--color-blue);
	}
}

.hero--light .hero__content__button:hover {
	background-color: var(--color-red);
	color: var(--color-white);
}

@media (min-width: 1024px) {
	.hero--light .hero__content__button:focus {
		background-color: var(--color-white);
		color: var(--color-red);
	}
}

.hero--text .hero__content__button {
	background-color: var(--color-white);
	color: var(--color-blue);
}

.hero--text .hero__content__button:hover {
	background-color: var(--color-red);
	color: var(--color-white);
}

.hero--text .hero__content__button:focus {
	background-color: var(--color-white);
	color: var(--color-red);
}

.hero__navigation {
	display: flex;
	position: absolute;
	top: calc(50vh - 20px);
	right: 0;
	z-index: 3;
}

@media (min-height: 461px) and (max-width: 767px) {
	.hero__navigation {
		top: calc(60vh - 20px);
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.hero__navigation {
		top: calc(70vh - 20px);
	}
}

@media (min-width: 1024px) {
	.hero__navigation {
		top: auto;
		right: 3.75%;
		bottom: 6.25rem;
	}
}

@media (min-width: 1806px) {
	.hero__navigation {
		right: calc((100vw - 1680px) / 2);
	}
}

.hero__navigation__button {
	appearance: none;
	background-color: var(--color-blue);
	border: 0;
	box-shadow: none;
	display: block;
	outline: 0;
	padding: 0;
	position: relative;
	transition: background-color 96ms linear;
	width: 2.5rem;
	height: 2.5rem;
}

@media (min-width: 768px) {
	.hero__navigation__button {
		width: 3rem;
		height: 3rem;
	}
}

@media (min-width: 1024px) {
	.hero__navigation__button {
		background-color: var(--color-white);
		width: 3.375rem;
		height: 3.375rem;
	}
}

.hero__navigation__button:not(:only-child):not(:first-child) {
	margin-left: 5px;
}

.hero__navigation__button svg:not(.icon) {
	display: none;
}

.hero__navigation__button .icon {
	fill: var(--color-white);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 9px;
	height: 9px;
}

@media (min-width: 768px) {
	.hero__navigation__button .icon {
		width: 11px;
		height: 11px;
	}
}

@media (min-width: 1024px) {
	.hero__navigation__button .icon {
		fill: var(--color-blue);
		width: 10px;
		height: 10px;
	}
}

.hero__navigation__button:hover {
	background-color: var(--color-blue);
	cursor: pointer;
}

.hero__navigation__button:hover .icon {
	fill: var(--color-white);
}

@media (max-width: 1023px) {
	.hero__navigation__button:active:not(.is-disabled),
	.hero__navigation__button:focus:not(.is-disabled) {
		background-color: var(--color-blue);
	}
}

@media (max-width: 1023px) {
	.hero__navigation__button:active:not(.is-disabled) .icon,
	.hero__navigation__button:focus:not(.is-disabled) .icon {
		fill: var(--color-white);
	}
}

.hero__navigation__button.is-disabled {
	background-color: var(--color-blue-light);
	cursor: default;
	pointer-events: none;
}

.hero__navigation__button.is-disabled .icon {
	fill: var(--color-white);
}

@media (min-width: 1024px) {
	.hero__navigation__button.is-disabled {
		background-color: rgb(255 255 255 / 0.6);
	}
}

.hero__navigation__button.is-disabled .icon {
	fill: #cccccc;
}

@media (min-width: 1024px) {
	.hero__navigation__button.is-disabled .icon {
		fill: var(--color-white);
	}
}
