.filter {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	border-top: 1px solid var(--color-blue);
	color: var(--color-blue);
	font-family: var(--font-sans);
}

.filter__list,
.filter__tags {
	border-left: 1px solid var(--color-blue);
	list-style: none;
	margin: 0;
	padding: 0;
}

.filter__list {
	display: flex;
	flex-wrap: wrap;
}

@media (--mq-from-medium) {
	.filter__list {
		flex-wrap: nowrap;
	}
}

.filter__item {
	border-right: 1px solid var(--color-blue);
	flex-basis: 100px;
	flex-grow: 1;
	flex-shrink: 0;
	margin: 0;
}

@media (--mq-from-small) {
	.filter__item {
		flex-basis: 100px;
	}
}

@media (--mq-up-to-medium) {
	.filter__item {
		border-bottom: 1px solid var(--color-blue);
		flex-basis: 150px;
	}
}

@media (--mq-from-medium) {
	.filter__item {
		flex-basis: auto;
		flex-shrink: 1;
	}
}

.filter__link {
	appearance: none;
	background: 0;
	border: 0;
	box-shadow: none;
	outline: 0;
}

@media (--mq-from-medium) {
	.filter__link {
		border-bottom: 1px solid var(--color-blue);
	}
}

.filter__link:hover {
	cursor: pointer;
}

.filter__link,
.filter__tags__toggle,
.filter__tags__label {
	box-sizing: border-box;
	color: var(--color-blue);
	text-align: center;
	text-decoration: none;
	transition:
		background-color 96ms linear,
		color 96ms linear;
	height: 100%;
}

@media (--mq-from-small) {
	.filter__link,
	.filter__tags__toggle,
	.filter__tags__label {
		font-size: 1.125rem;
	}
}

@media (--mq-from-wide) {
	.filter__link,
	.filter__tags__toggle,
	.filter__tags__label {
		font-size: 1.25rem;
	}
}

.filter__link,
.filter__tags__toggle {
	display: block;
	line-height: 1;
	padding: 1rem 0.25rem;
	width: 100%;
}

@media (--mq-from-small) {
	.filter__link,
	.filter__tags__toggle {
		padding: 1rem 0.5rem;
	}
}

@media (--mq-from-medium) {
	.filter__link,
	.filter__tags__toggle {
		padding: 1rem;
	}
}

@media (--mq-from-wide) {
	.filter__link,
	.filter__tags__toggle {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
}

.filter__link:hover,
.filter__link.filter__link--active,
.filter__link.is-active,
.filter__tags__toggle:hover,
.filter__tags__toggle.filter__link--active,
.filter__tags__toggle.is-active {
	background-color: var(--color-blue);
	color: var(--color-white);
}

.filter__tags {
	border-right: 1px solid var(--color-blue);
	border-bottom: 1px solid var(--color-blue);
	text-align: center;
}

.filter__tags__toggle {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	display: block;
	margin: 0;
	outline: 0;
	width: 100%;
}

.filter__tags__toggle:hover {
	cursor: pointer;
}

.filter__tags.is-open .filter__tags__toggle {
	display: none;
}

.filter__tags__form {
	display: none;
}

.filter__tags.is-open .filter__tags__form {
	display: block;
}

.filter__tags__submit {
	display: none;
}

.filter__tags__list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
}

.filter__tags__item {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
	margin: 0;
}

@media (--mq-from-small) {
	.filter__tags__item {
		flex-basis: auto;
		flex-grow: 0;
	}
}

.filter__tags__item > input {
	display: none;
}

.filter__tags__label {
	cursor: pointer;
	display: inline-block;
	padding: 1rem 2rem 1rem 2.5rem;
	position: relative;
	user-select: none;
}

@media (--mq-from-small) {
	.filter__tags__label {
		display: block;
		line-height: 50px;
		padding: 0 2rem 0 1.5rem;
		width: 100%;
		height: 50px;
	}
}

@media (--mq-from-wide) {
	.filter__tags__label {
		line-height: 68px;
		height: 68px;
	}
}

.filter__tags__item:last-child .filter__tags__label {
	padding-right: 0;
}

.filter__tags__label::before {
	border: 1px solid var(--color-blue);
	border-radius: 0;
	box-sizing: border-box;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: 1rem;
	transform: translateY(-50%);
	width: 14px;
	height: 14px;
}

@media (--mq-from-small) {
	.filter__tags__label::before {
		left: 0;
	}
}

.filter__tags__label:hover {
	cursor: pointer;
}

.filter__tags__label.is-active::before,
input:checked + .filter__tags__label::before {
	background-color: var(--color-blue);
}

.filter__tags__label:hover {
	color: var(--color-red);
}
