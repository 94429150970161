.preview {
	background-color: var(--color-white);
	border: 1px solid #d4e7fe;
	box-sizing: border-box;
	display: flex;
	padding: 0.5rem;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

@media (min-width: 480px) {
	.preview {
		min-height: 130px;
	}
}

.preview-group .preview {
	flex-basis: calc(50% - 1rem);
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0 0.5rem 1rem;
}

@media (min-width: 480px) {
	.preview-group .preview {
		flex-basis: calc(33.33% - 1rem);
	}
}

@media (--mq-from-xsmall) {
	.preview-group .preview {
		flex-basis: calc(25% - 1rem);
	}
}

@media (--mq-from-small) {
	.preview-group .preview {
		flex-basis: calc(20% - 1rem);
	}
}

@media (--mq-from-medium) {
	.preview-group .preview {
		flex-basis: calc(16.66% - 2rem);
		margin: 0 1rem 2rem;
	}
}

@media (--mq-from-wide) {
	.preview-group .preview {
		flex-basis: calc(12.5% - 2rem);
	}
}

.preview__image {
	display: block;
	margin: 0;
	width: 100%;
	height: auto;
	max-height: 100%;
}

.preview__content {
	background-color: var(--color-blue);
	box-sizing: border-box;
	color: var(--color-white);
	padding: 2rem;
	width: 400px;
}

@media (--mq-from-wide) {
	.preview__content {
		padding: 2.625rem;
	}
}

mr-preview:not(.is-resolved) .preview__content {
	display: none;
}

.preview__content strong {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--font-sans);
	font-weight: 600;
}

@media (--mq-from-wide) {
	.preview__content strong {
		font-size: 1.125rem;
	}
}

.preview__content p {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1rem;
	font-weight: 400;
}

.preview__content p:not(:only-child):not(:last-child) {
	margin-bottom: 1rem;
}

mr-preview {
	display: block;
	z-index: 999999;
}
