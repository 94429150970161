body {
	background-color: var(--color-white);
	color: var(--color-foreground);
}

@media (max-width: 767px) {
	body {
		padding-top: 3.1875rem;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	body {
		padding-top: 3.625rem;
	}
}

body.has-open-navigation {
	overflow: hidden;
}

*::selection {
	background-color: #ff544b;
	color: #ffffff;
}

button:focus {
	outline: 0;
}

img {
	width: 100%;
	max-width: 100%;
}

table {
	border-collapse: collapse;
	font-family: var(--font-sans);
	font-size: 1rem;
	margin: 2rem 0;
}

@media (min-width: 768px) {
	table {
		margin: 3rem 0;
	}
}

td,
th {
	padding: 0.75rem 1rem;
	text-align: left;
}

@media (min-width: 768px) {
	td,
	th {
		padding: 1rem 1.875rem;
	}
}

table,
th,
td {
	border: 1px solid var(--color-blue);
}

hr {
	background-color: var(--color-blue);
	border: none;
	height: 1px;
}

ol:not([class]),
ul:not([class]) {
	list-style: none;
	margin-left: 0;
	padding-left: 1.5rem;
}

ol:not([class]) {
	counter-reset: ol-list-items;
}

ol:not([class]) ol:not([class]) {
	counter-reset: ol-sub-list-items;
	padding-left: 1.75rem;
}

@media (min-width: 768px) {
	ol:not([class]) ol:not([class]) {
		padding-left: 1.875rem;
	}
}

li:not([class]) {
	position: relative;
}

li:not([class])::before {
	position: absolute;
	left: -1.25rem;
}

@media (min-width: 768px) {
	li:not([class])::before {
		left: -1.5rem;
	}
}

ul:not([class]) li:not([class])::before {
	background-color: var(--color-blue);
	border-radius: 4px;
	content: "";
	display: block;
	top: 11px;
	transition: background-color 96ms linear;
	width: 4px;
	height: 4px;
}

.call-to-action--dark ul:not([class]) li:not([class])::before {
	background-color: var(--color-blue-lightened);
}

ol:not([class]) li:not([class])::before {
	color: var(--color-blue);
	content: counter(ol-list-items);
	counter-increment: ol-list-items;
	font-size: 0.875rem;
	font-weight: 700;
	line-height: 1.75rem;
	top: 0;
}

@media (min-width: 1024px) {
	ol:not([class]) li:not([class])::before {
		font-size: 0.9375rem;
		line-height: 2rem;
	}
}

ol:not([class]) ol:not([class]) li:not([class])::before {
	content: counter(ol-list-items) "." counter(ol-sub-list-items);
	counter-increment: ol-sub-list-items;
	left: -1.75rem;
}

@media (min-width: 768px) {
	ol:not([class]) ol:not([class]) li:not([class])::before {
		left: -1.875rem;
	}
}
