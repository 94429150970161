.embedded-media {
	background-color: var(--color-blue-light);
	display: block;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.embedded-media.embedded-media--ratio-1-1 {
	padding-bottom: 100%;
}

.embedded-media.embedded-media--ratio-1-2 {
	padding-bottom: 200%;
}

.embedded-media.embedded-media--ratio-2-1 {
	padding-bottom: 50%;
}

.embedded-media.embedded-media--ratio-2-3 {
	padding-bottom: 150%;
}

.embedded-media.embedded-media--ratio-3-2 {
	padding-bottom: 66.6666%;
}

.embedded-media.embedded-media--ratio-5-6 {
	padding-bottom: 120%;
}

.embedded-media.embedded-media--ratio-6-5 {
	padding-bottom: 83.3333%;
}

.embedded-media.embedded-media--ratio-9-16 {
	padding-bottom: 177.7777%;
}

.embedded-media > p {
	color: var(--color-blue);
	font-family: var(--font-sans);
	position: absolute;
	text-align: center;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
}

.embedded-media > *:not(p),
.embedded-media iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
}
