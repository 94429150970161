.showcase {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: block;
	position: relative;
	height: 66.6666vh;
	min-height: 400px;
}

@media (--mq-from-small) {
	.showcase {
		min-height: 500px;
	}
}

@media (--mq-from-small) {
	.showcase {
		min-height: 640px;
	}
}

@media (--mq-from-wide) {
	.showcase {
		min-height: 750px;
	}
}

.showcase__images {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.showcase__images .slideshow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.showcase__image {
	position: relative;
}

.showcase__image__image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	min-width: 100%;
	max-width: none;
	height: auto;
	min-height: 100%;
}

@supports (object-fit: cover) {
	.showcase__image__image {
		object-fit: cover;
		object-position: center;
		top: 0;
		left: 0;
		transform: none;
		width: 100%;
		height: 100%;
	}
}

.showcase__image__caption {
	color: var(--color-white);
	font-family: var(--font-sans);
	font-size: 0.75rem;
	position: absolute;
	text-align: right;
	top: 1rem;
	right: 6.25%;
}

@media (--mq-from-small) {
	.showcase__image__caption {
		right: 3.75%;
	}
}

@media (--mq-from-medium) {
	.showcase__image__caption {
		top: auto;
		bottom: 0.5rem;
	}
}

@media (--mq-from-wide) {
	.showcase__image__caption {
		bottom: 1rem;
	}
}

@media (min-width: 1806px) {
	.showcase__image__caption {
		right: calc((100vw - 1680px) / 2);
	}
}

.showcase__content {
	background-color: var(--color-white);
	box-sizing: border-box;
	padding-bottom: 1.25rem;
	position: absolute;
	bottom: 6.25%;
	left: 6.25%;
	width: 87.5%;
	z-index: 2;
}

@media (--mq-from-small) {
	.showcase__content {
		padding-bottom: 2rem;
		bottom: 3.75vw;
		left: 3.75%;
		width: 57.5%;
	}
}

@media (--mq-from-medium) {
	.showcase__content {
		width: 50%;
	}
}

@media (min-width: 1280px) {
	.showcase__content {
		padding-bottom: 3rem;
		width: 46.25%;
	}
}

@media (min-width: 1505px) {
	.showcase__content {
		left: calc((100% - 1400px) / 2);
		width: calc(50% - ((100% - 1400px) / 2));
	}
}

.showcase__fixed {
	color: var(--color-blue);
	font-family: var(--font-sans);
	font-size: 1.0625rem;
	font-weight: 600;
	line-height: 1.3333;
	margin-bottom: 2.5rem;
	padding-top: 1.25rem;
	padding-left: 1.25rem;
}

@media (--mq-up-to-xsmall) {
	.showcase__fixed {
		box-sizing: border-box;
		padding-right: 33.333%;
	}
}

@media (--mq-from-xsmall) {
	.showcase__fixed {
		font-size: 1.125rem;
		margin-bottom: 3rem;
	}
}

@media (--mq-from-small) {
	.showcase__fixed {
		padding-top: 2rem;
		padding-left: 2rem;
	}
}

@media (--mq-from-medium) {
	.showcase__fixed {
		font-size: 1.25rem;
	}
}

@media (min-width: 1280px) {
	.showcase__fixed {
		margin-bottom: 2.5rem;
		padding-top: 3rem;
		padding-left: 3rem;
	}
}

.showcase__buttons {
	position: absolute;
	top: 0;
	right: 0;
}

.showcase__button {
	appearance: none;
	background-color: var(--color-blue);
	border: none;
	color: var(--color-white);
	position: relative;
	text-indent: -9999rem;
	transition: background-color 96ms linear;
	width: 2.5rem;
	height: 2.5rem;
	z-index: 4;
}

@media (--mq-from-small) {
	.showcase__button {
		width: 3rem;
		height: 3rem;
	}
}

@media (--mq-from-medium) {
	.showcase__button {
		width: 3.375rem;
		height: 3.375rem;
	}
}

.showcase__button::before {
	color: var(--color-white);
	font-family: var(--font-sans);
	font-size: 1.125rem;
	position: absolute;
	text-indent: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: color 96ms linear;
	z-index: 1;
}

@media (--mq-from-small) {
	.showcase__button::before {
		font-size: 1.375rem;
	}
}

@media (--mq-from-medium) {
	.showcase__button::before {
		font-size: 1.25rem;
	}
}

.showcase__button:hover:not(:focus) {
	cursor: pointer;
}

.showcase__button:hover:not(:focus)::before {
	color: var(--color-red);
}

@media (--mq-up-to-medium) {
	.showcase__button:active:not(.is-disabled),
	.showcase__button:focus:not(.is-disabled) {
		background-color: var(--color-blue);
	}
}

.showcase__button.is-disabled {
	background-color: rgb(33 75 154 / 0.2);
	cursor: default;
	pointer-events: none;
}

.showcase__button--next::before {
	content: "›";
}

.showcase__button--previous::before {
	content: "‹";
}

.showcase__captions {
	overflow: hidden;
}

.showcase__caption {
	box-sizing: border-box;
	color: var(--color-blue);
	display: block;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
}

@media (--mq-from-small) {
	.showcase__caption {
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

@media (min-width: 1280px) {
	.showcase__caption {
		padding-right: 3rem;
		padding-left: 3rem;
	}
}

.showcase__caption h4 {
	font-size: 1.5rem;
	line-height: 1.2;
	margin-bottom: 0;
}

@media (--mq-from-small) {
	.showcase__caption h4 {
		font-size: 1.875rem;
	}
}

@media (--mq-from-medium) {
	.showcase__caption h4 {
		font-size: 2rem;
	}
}

@media (--mq-from-wide) {
	.showcase__caption h4 {
		font-size: 2.5rem;
	}
}

@media (--mq-from-large) {
	.showcase__caption h4 {
		font-size: 3.125rem;
	}
}
