@media (--mq-up-to-medium) {
	.text-banner {
		background-color: var(--color-blue-light);
	}
}

.text-banner__title {
	color: var(--color-blue);
	font-weight: 400;
	line-height: 1.6666;
	margin-bottom: 2rem;
}

@media (--mq-from-medium) {
	.text-banner__title {
		margin-bottom: 3rem;
	}
}

@media (--mq-from-wide) {
	.text-banner__title {
		font-size: 2.25rem;
		line-height: 1.3333;
	}
}

.text-banner__content {
	color: var(--color-blue);
	margin-bottom: 1.5rem;
}

@media (--mq-from-small) {
	.text-banner__content {
		font-size: 1.0625rem;
	}
}

@media (--mq-from-medium) {
	.text-banner__content {
		margin-bottom: 2.5rem;
	}
}

@media (--mq-from-wide) {
	.text-banner__content {
		font-size: 1.375rem;
	}
}

@media (--mq-up-to-xsmall) {
	.text-banner__button {
		width: 100%;
	}
}
