@media (--mq-from-medium) {
	.meta-navigation {
		background-color: var(--color-blue-dark);
		overflow: hidden;
		padding-right: 3rem;
		padding-left: 3rem;
		height: 2.75rem;
	}
}

@media (--mq-from-wide) {
	.meta-navigation {
		padding-right: 3.75rem;
		padding-left: 3.75rem;
	}
}

@media (--mq-up-to-medium) {
	.site-header .meta-navigation {
		display: none;
	}
}

.is-scrolled .site-header .meta-navigation {
	display: none;
}

@media (--mq-up-to-medium) {
	.has-open-navigation .mobile-navigation .meta-navigation {
		display: block;
	}
}

@media (--mq-from-small) {
	.site-footer .meta-navigation {
		background-color: transparent;
		padding-right: 0;
		padding-left: 0;
	}
}

.meta-navigation__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (--mq-from-medium) {
	.meta-navigation__list {
		display: flex;
		margin-right: -1rem;
		overflow: hidden;
		justify-content: flex-end;
	}
}

@media (--mq-from-small) {
	.site-footer .meta-navigation__list {
		display: flex;
		margin-right: -0.75rem;
		overflow: hidden;
		justify-content: flex-end;
	}
}

@media (--mq-from-medium) {
	.site-footer .meta-navigation__list {
		margin-right: -1rem;
	}
}

@media (--mq-from-small) {
	.site-footer .meta-navigation:first-child .meta-navigation__list {
		margin-right: 0;
		margin-left: -0.75rem;
	}
}

@media (--mq-from-medium) {
	.site-footer .meta-navigation:first-child .meta-navigation__list {
		margin-right: 0;
		margin-left: -1rem;
	}
}

.site-footer .meta-navigation:last-child .meta-navigation__list {
	display: flex;
	overflow: hidden;
	justify-content: flex-end;
}

@media (--mq-up-to-small) {
	.site-footer .meta-navigation:last-child .meta-navigation__list {
		flex-wrap: wrap;
		margin-right: 0;
		justify-content: center;
	}
}

.meta-navigation__item {
	margin: 0;
}

.meta-navigation__label,
.meta-navigation__link {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--color-white);
	display: inline-block;
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1;
	padding: 0.9375rem 1rem;
	text-decoration: none;
}

.site-footer .meta-navigation__label,
.site-footer .meta-navigation__link {
	color: var(--color-blue-lightened);
}

@media (--mq-up-to-small) {
	.site-footer .meta-navigation__label,
	.site-footer .meta-navigation__link {
		box-sizing: border-box;
		padding: 0.5rem 0.75rem;
		text-align: center;
		width: 100%;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.site-footer .meta-navigation__label,
	.site-footer .meta-navigation__link {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}
}

@media (--mq-up-to-medium) {
	.has-open-navigation .mobile-navigation .meta-navigation__label,
	.has-open-navigation .mobile-navigation .meta-navigation__link {
		color: var(--color-blue);
		padding: 0.5rem 0;
	}
}

@media (--mq-up-to-medium) {
	.has-open-navigation .mobile-navigation .meta-navigation__label:first-child,
	.has-open-navigation .mobile-navigation .meta-navigation__link:first-child {
		padding-top: 0;
	}
}

.meta-navigation__link:hover {
	color: var(--color-red);
}

.meta-navigation__link.meta-navigation__link--active {
	font-family: var(--font-sans);
	font-weight: 600;
	line-height: 0.875rem;
}
