.site-header {
	background-color: var(--color-white);
	display: block;
	z-index: 98;
}

.styleguide .site-header {
	position: relative;
}

@media (max-width: 1023px) {
	.site-header {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		transition: background-color 96ms linear;
		width: 100%;
	}
}

@media (max-width: 1023px) {
	.has-open-navigation .site-header {
		background-color: var(--color-blue);
	}
}

@media (min-width: 1024px) {
	.is-scrolled .site-header {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		transform: translateY(calc(-3.625rem - 2px));
		transition: none;
		width: 100%;
		will-change: transform;
	}
}

@media (min-width: 1380px) {
	.is-scrolled .site-header {
		transform: translateY(calc(-3.8125rem - 4px));
	}
}

@media (min-width: 1024px) {
	.is-scrolled.is-showing-nav .site-header {
		transform: translateY(0);
		transition: transform 488ms cubic-bezier(0.2, 0, 0.3, 1);
	}
}

body:not(.t-light):not(.has-open-navigation) .site-header.site-header--fill {
	background-color: var(--color-white);
}

.site-header__corner {
	backface-visibility: hidden;
	content: "";
	display: block;
	position: absolute;
	width: 2rem;
	height: 2rem;
}

@media (min-width: 1024px) {
	.site-header__corner {
		top: 2.75rem;
		right: 0;
		z-index: 99;
	}
}

@media (min-width: 1024px) {
	.is-scrolled .site-header__corner {
		top: 0;
	}
}

@media (max-width: 1023px) {
	body:not(.has-open-navigation) .site-header__corner {
		display: none;
	}
}

@media (max-width: 1023px) {
	.has-open-navigation .site-header__corner {
		border-top: 1px solid var(--color-blue);
		border-right: 1px solid var(--color-blue);
		right: 45px;
		z-index: 99;
	}
}

@media (max-width: 767px) {
	.has-open-navigation .site-header__corner {
		top: 49px;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.has-open-navigation .site-header__corner {
		top: 57px;
	}
}

@media (min-width: 1024px) {
	.site-header__corner .site-header__corner__foreground {
		fill: var(--color-white);
	}
}

@media (min-width: 1024px) {
	.is-scrolled .site-header__corner .site-header__corner__foreground,
	.site-header--fill .site-header__corner .site-header__corner__foreground {
		fill: #d4e7fe;
	}
}

@media (max-width: 1023px) {
	.has-open-navigation .site-header__corner .site-header__corner__foreground {
		fill: #d4e7fe;
	}
}

@media (min-width: 1024px) {
	body.is-hero-with-light-background:not(.is-scrolled) .site-header__corner .site-header__corner__foreground {
		fill: #d4e7fe;
	}
}

@media (min-width: 1024px) {
	.site-header__corner .site-header__corner__background {
		fill: var(--color-blue-dark);
	}
}

@media (max-width: 1023px) {
	.has-open-navigation .site-header__corner .site-header__corner__background {
		fill: var(--color-blue);
	}
}

.site-header__wrapper {
	display: flex;
	padding-top: 1rem;
	padding-bottom: 1rem;
	align-items: stretch;
}

@media (min-width: 768px) {
	.site-header__wrapper {
		width: 92.5%;
	}
}

@media (min-width: 1024px) {
	.site-header__wrapper {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}

@media (min-width: 1380px) and (min-height: 800px) {
	.site-header__wrapper {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
}

.site-header__wrapper::after {
	background-image: url("../images/dot.svg");
	background-repeat: repeat-x;
	background-size: 7px 2px;
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translateY(50%);
	width: 100%;
	height: 2px;
}

@media (min-width: 768px) {
	.site-header__wrapper::after {
		background-size: 10px 3px;
		height: 3px;
	}
}

.has-open-navigation .site-header__wrapper::after {
	background: none;
}

@media (min-width: 1024px) {
	.is-scrolled .site-header__wrapper {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

.site-header__left,
.site-header__right {
	display: flex;
	flex-basis: 50%;
	flex-grow: 0;
	flex-shrink: 0;
	align-items: center;
}

@media (min-width: 1024px) {
	.site-header__left,
	.site-header__right {
		flex-basis: 150px;
	}
}

@media (min-width: 1380px) {
	.site-header__left,
	.site-header__right {
		flex-basis: 200px;
	}
}

@media (min-width: 1680px) {
	.site-header__left,
	.site-header__right {
		flex-basis: 250px;
	}
}

.site-header__center {
	flex-grow: 1;
	flex-shrink: 1;
}

@media (max-width: 1023px) {
	.site-header__center {
		display: none;
	}
}

.site-header__right {
	justify-content: flex-end;
}

.site-header__logo {
	display: block;
	position: relative;
	width: 100px;
	height: 18px;
}

@media (min-width: 768px) {
	.site-header__logo {
		width: 150px;
		height: 26px;
	}
}

@media (min-width: 1024px) {
	.site-header__logo {
		height: 100%;
	}
}

@media (min-width: 1380px) {
	.site-header__logo {
		width: 200px;
	}
}

@media (min-width: 1680px) {
	.site-header__logo {
		width: 250px;
	}
}

@media (min-width: 1024px) {
	.is-scrolled .site-header__logo {
		width: 100px;
	}
}

@media (min-width: 1380px) {
	.is-scrolled .site-header__logo {
		width: 150px;
	}
}

.site-header__logo svg {
	display: block;
	fill: var(--color-blue);
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: fill 96ms linear;
	width: 100%;
	height: 100%;
}

@media (min-width: 1024px) {
	.site-header__logo svg {
		transform: translateY(calc(-50% + 2px));
		height: 26px;
	}
}

@media (min-width: 1380px) {
	.site-header__logo svg {
		transform: translateY(calc(-50% - 2px));
		height: 35px;
	}
}

@media (min-width: 1680px) {
	.site-header__logo svg {
		transform: translateY(calc(-50% - 4px));
		height: 44px;
	}
}

@media (min-width: 1024px) {
	.is-scrolled .site-header__logo svg {
		transform: translateY(-50%);
		height: 18px;
	}
}

@media (min-width: 1380px) {
	.is-scrolled .site-header__logo svg {
		height: 26px;
	}
}

@media (max-width: 1023px) {
	.has-open-navigation .site-header__logo svg {
		fill: var(--color-white);
	}
}

@media (min-width: 1024px) {
	.site-header__logo:hover svg {
		cursor: pointer;
		fill: var(--color-red);
	}
}

.site-header__toggle {
	display: flex;
	justify-content: center;
	align-items: center;
}

.site-header__button {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	outline: 0;
	padding: 0;
	position: relative;
	width: 18px;
	height: 18px;
}

@media (min-width: 768px) {
	.site-header__button {
		width: 20px;
		height: 20px;
	}
}

@media (min-width: 1380px) {
	.site-header__button {
		width: 26px;
		height: 26px;
	}
}

@media (min-width: 1024px) and (max-width: 1379px) {
	.is-scrolled .site-header__button {
		width: 20px;
		height: 20px;
	}
}

.site-header__button svg {
	display: block;
	fill: var(--color-blue);
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: fill 96ms linear;
	width: 100%;
	height: 100%;
}

.site-header__button:hover svg {
	cursor: pointer;
}

@media (min-width: 1024px) {
	.site-header__button:hover svg {
		fill: var(--color-red);
	}
}

@media (max-width: 1023px) {
	.is-scrolled:not(.has-open-navigation) .site-header__button:hover svg {
		fill: var(--color-blue);
	}
}

@media (max-width: 1023px) {
	.has-open-navigation .site-header__search-toggle {
		display: none;
	}
}

.site-header__menu-toggle {
	margin-left: 0.75rem;
}

@media (min-width: 1024px) {
	.site-header__menu-toggle {
		display: none;
	}
}

.site-header__menu-toggle .icon-close {
	display: none;
}

@media (max-width: 1023px) {
	.has-open-navigation .site-header__menu-toggle .icon-close {
		display: block;
		fill: var(--color-white);
	}
}

@media (max-width: 1023px) {
	.has-open-navigation .site-header__menu-toggle .icon-menu {
		display: none;
	}
}
