.page-header {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.page-header.page-header--centered {
	text-align: center;
}

@media (--mq-from-medium) {
	main > .page-header:only-child {
		min-height: 560px;
	}
}

.page-header__wrapper {
	position: relative;
	z-index: 1; /* The z-index stacking context is needed to keep the sidebar click-able */
}

@media (--mq-up-to-small) {
	.page-header:not(.page-header--centered) .page-header__title {
		margin-right: auto;
		margin-left: auto;
		width: 87.5%;
	}
}

@media (--mq-from-small) {
	.page-header:not(.page-header--centered) .page-header__title {
		box-sizing: border-box;
		padding-right: 3.75%;
		padding-left: 16.6666%;
		width: 100%;
	}
}

@media (--mq-from-medium) {
	.page-header:not(.page-header--centered) .page-header__title {
		padding-right: 17.5%;
		padding-left: 25%;
	}
}

@media (min-width: 1400px) {
	.page-header:not(.page-header--centered) .page-header__title {
		padding-right: calc((1400px * 0.175) + ((100% - 1400px) / 2));
		padding-left: calc((1400px * 0.25) + ((100% - 1400px) / 2));
	}
}

.page-header__title h1 {
	font-weight: 400;
	margin: 0;
}

.page-header__title--with-intro {
	margin-bottom: 2rem;
}

@media (--mq-from-small) {
	.page-header__title--with-intro {
		margin-bottom: 3rem;
	}
}

@media (--mq-from-wide) {
	.page-header__title--with-intro {
		margin-bottom: 4rem;
	}
}

@media (--mq-from-large) {
	.page-header__title--with-intro {
		margin-bottom: 5rem;
	}
}

@media (--mq-from-medium) {
	.page-header__intro {
		min-height: 110px;
	}
}

.page-header__intro p {
	color: var(--color-blue);
	font-size: 1.125rem;
	line-height: 1.45;
}

@media (--mq-from-small) {
	.page-header__intro p {
		font-size: 1.25rem;
	}
}

@media (--mq-from-wide) {
	.page-header__intro p {
		font-size: 1.375rem;
	}
}

.page-header__intro p:last-child,
.page-header__intro p:only-child {
	margin: 0;
}

.page-header--centered .page-header__intro p > a {
	border-bottom: 1px solid;
	text-decoration: none;
	white-space: nowrap;
}

@media (--mq-up-to-medium) {
	.page-header__sidebar {
		display: none;
	}
}

@media (--mq-from-medium) {
	.page-header__sidebar {
		position: absolute;
		top: 0.625rem;
		left: 3.75%;
	}
}

@media (--mq-from-wide) {
	.page-header__sidebar {
		top: 0.875rem;
	}
}

@media (min-width: 1505px) {
	.page-header__sidebar {
		left: calc((100vw - 1400px) / 2);
	}
}
