@font-face {
	font-family: "Lora";
	font-style: normal;
	font-weight: 400;
	src:
		local("Lora Regular"),
		local("Lora-Regular"),
		url("https://fonts.gstatic.com/s/lora/v12/0QIvMX1D_JOuMwT7I_FMl_GW8g.woff2") format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: "Lora";
	font-style: normal;
	font-weight: 400;
	src:
		local("Lora Regular"),
		local("Lora-Regular"),
		url("https://fonts.gstatic.com/s/lora/v12/0QIvMX1D_JOuMwr7I_FMl_E.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Lora";
	font-style: normal;
	font-weight: 700;
	src:
		local("Lora Bold"),
		local("Lora-Bold"),
		url("https://fonts.gstatic.com/s/lora/v12/0QIgMX1D_JOuO7HeNtJumtus-7zu-Q.woff2") format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: "Lora";
	font-style: normal;
	font-weight: 700;
	src:
		local("Lora Bold"),
		local("Lora-Bold"),
		url("https://fonts.gstatic.com/s/lora/v12/0QIgMX1D_JOuO7HeNtxumtus-7w.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Neutrif Studio";
	src: url("../fonts/364152_8_0.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "Neutrif Studio";
	src: url("../fonts/364152_5_0.woff2") format("woff2");
	font-style: normal;
	font-weight: 600;
}
