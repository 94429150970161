.dropcap > p:first-child::first-letter {
	box-sizing: border-box;
	color: var(--color-blue);
	float: left;
	font-family: var(--font-sans);
	font-size: 3.125rem;
	line-height: 2rem;
	padding: 1rem 0.5rem 0 0;
}

@media (--mq-from-medium) {
	.dropcap > p:first-child::first-letter {
		font-size: 3.375rem;
		padding: 1.125rem 0.5rem 0 0;
	}
}
