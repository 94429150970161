body {
	font-family: var(--font-serif);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: currentColor;
	font-family: var(--font-sans);
	font-weight: 600;
	margin-top: 2rem;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

h1:not([class]),
h2:not([class]),
h3:not([class]),
h4:not([class]),
h5:not([class]),
h6:not([class]) {
	color: var(--color-blue);
}

h1 {
	font-size: 1.75rem;
	line-height: 1.3333;
}

@media (--mq-from-small) {
	h1 {
		font-size: 2rem;
	}
}

@media (--mq-from-medium) {
	h1 {
		font-size: 3rem;
	}
}

@media (--mq-from-wide) {
	h1 {
		font-size: 3.75rem;
	}
}

h2 {
	font-size: 1.5rem;
	line-height: 1.2;
}

@media (--mq-from-small) {
	h2 {
		font-size: 1.875rem;
	}
}

@media (--mq-from-medium) {
	h2 {
		font-size: 2rem;
	}
}

@media (--mq-from-wide) {
	h2 {
		font-size: 2.5rem;
	}
}

h3 {
	font-size: 1.25rem;
	line-height: 1.3333;
}

@media (--mq-from-small) {
	h3 {
		font-size: 1.5rem;
	}
}

@media (--mq-from-medium) {
	h3 {
		font-size: 1.625rem;
	}
}

h4 {
	font-size: 1.125rem;
	line-height: 1.3333;
}

@media (--mq-from-small) {
	h4 {
		font-size: 1.25rem;
	}
}

@media (--mq-from-wide) {
	h4 {
		font-size: 1.375rem;
	}
}

h5 {
	font-size: 1.0625rem;
	line-height: 1.3333;
}

@media (--mq-from-small) {
	h5 {
		font-size: 1.125rem;
	}
}

@media (--mq-from-medium) {
	h5 {
		font-size: 1.25rem;
	}
}

h6 {
	font-size: 1rem;
}

@media (--mq-from-small) {
	h6 {
		font-size: 1.125rem;
	}
}

strong {
	font-weight: 700;
}

a {
	color: currentColor;
	transition: color 96ms linear;
}

a:not([class]):hover {
	color: var(--color-red);
}

p {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.6666;
	margin-top: 0;
	margin-bottom: 2rem;
}

@media (--mq-from-medium) {
	p {
		font-size: 1.125rem;
	}
}

p:last-child {
	margin-bottom: 0;
}

dd,
dt {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.75;
	margin: 0;
	padding: 0;
}

dt {
	color: var(--color-blue-lightened);
}

dd {
	color: var(--color-blue);
}

blockquote {
	color: var(--color-blue);
	font-family: var(--font-sans);
	font-size: 1.5rem;
	font-weight: 400;
	margin: 2rem 0;
	text-align: center;
}

@media (--mq-from-small) {
	blockquote {
		font-size: 1.875rem;
		margin: 3rem 0;
	}
}

@media (--mq-from-medium) {
	blockquote {
		margin: 4rem 0;
	}
}

@media (--mq-from-wide) {
	blockquote {
		font-size: 2.5rem;
		margin: 6rem 0;
	}
}

blockquote::before {
	content: "“";
	font-size: 1.875rem;
	line-height: 1.3333;
}

@media (--mq-from-small) {
	blockquote::before {
		font-size: 2rem;
	}
}

@media (--mq-from-medium) {
	blockquote::before {
		font-size: 3rem;
	}
}

@media (--mq-from-wide) {
	blockquote::before {
		font-size: 5rem;
	}
}

blockquote p {
	font-size: inherit;
	line-height: 1.25;
}

blockquote p + p {
	font-size: 1.25rem;
}

ol,
ul {
	line-height: 1.6666;
	margin-bottom: 2rem;
}

li ol,
li ul {
	box-sizing: border-box;
	margin-bottom: 1rem;
	padding-top: 1rem;
}

li {
	margin: 0 0 1rem;
	padding: 0;
}

li:not([class]) {
	font-size: 1rem;
	margin: 0;
}

@media (--mq-from-medium) {
	li:not([class]) {
		font-size: 1.125rem;
	}
}

li:not([class]):last-child {
	margin: 0;
}

button {
	font-family: var(--font-sans);
}
