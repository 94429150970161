.flexible-content {
	position: relative;
}

.flexible-content__row {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

@media (min-width: 1024px) {
	.flexible-content__row {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
}

@media (min-width: 1280px) {
	.flexible-content__row {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
}

@media (min-width: 1380px) {
	.flexible-content__row {
		margin-top: 4.625rem;
		margin-bottom: 4.625rem;
	}
}

@media (min-width: 1680px) {
	.flexible-content__row {
		margin-top: 6.125rem;
		margin-bottom: 6.125rem;
	}
}

.flexible-content__row + .flexible-content__row,
.flexible-content__row:first-child {
	margin-top: 0;
}

.flexible-content__row:last-child {
	margin-bottom: 0;
}

@media (min-width: 1280px) {
	.flexible-content__row.flexible-content__row--image-group,
	.flexible-content__row.flexible-content__row--slideshow {
		margin-bottom: 2.1875rem;
	}
}

@media (min-width: 1380px) {
	.flexible-content__row.flexible-content__row--image-group,
	.flexible-content__row.flexible-content__row--slideshow {
		margin-bottom: 2.8125rem;
	}
}

@media (min-width: 1680px) {
	.flexible-content__row.flexible-content__row--image-group,
	.flexible-content__row.flexible-content__row--slideshow {
		margin-bottom: 4.3125rem;
	}
}

.flexible-content__row.flexible-content__row--text-content h2 {
	font-size: 1.25rem;
	line-height: 1.3333;
}

@media (min-width: 768px) {
	.flexible-content__row.flexible-content__row--text-content h2 {
		font-size: 1.5rem;
	}
}

@media (min-width: 1024px) {
	.flexible-content__row.flexible-content__row--text-content h2 {
		font-size: 1.625rem;
	}
}

.flexible-content__row.flexible-content__row--text-content h3 {
	font-size: 1.125rem;
	line-height: 1.3333;
}

@media (min-width: 768px) {
	.flexible-content__row.flexible-content__row--text-content h3 {
		font-size: 1.25rem;
	}
}

.flexible-content__row.flexible-content__row--text-content h4,
.flexible-content__row.flexible-content__row--text-content h5,
.flexible-content__row.flexible-content__row--text-content h6 {
	font-size: 1rem;
	line-height: 1.3333;
	text-transform: uppercase;
}
